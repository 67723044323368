// http://react-component.github.io/collapse/

$prefixCls: rc-collapse;
$text-color: #666;
$borderStyle: 1px solid #d9d9d9;

@mixin common() {
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}
@mixin right($w, $h, $color) {
  border-top: $w solid transparent;
  border-bottom: $w solid transparent;
  border-left: $h solid $color;
}

@mixin bottom($w, $h, $color) {
  border-left: $w solid transparent;
  border-right: $w solid transparent;
  border-top: $h solid $color;
}

.#{$prefixCls} {
  &-anim-active {
    transition: height .2s ease-out;
  }

  & > &-item {
    > .#{$prefixCls}-header {
      cursor: pointer;
      .arrow {
        display: none;
        //content: '\20';
        //vertical-align: middle;
      }
    }
  }

  &-content {
    overflow: hidden;
    &-inactive {
      display: none;
    }
  }
}
