@import "../../../app/assets/stylesheets/mixins/media-queries/index";
@import "../../../app/assets/stylesheets/shared/z-index";
@import "../styles/pxToRem.module.scss";

.logo {
  display: block;
  max-width: pxToRem(77);
  max-height: pxToRem(35);
  @include larger-than-tablet {
    max-width: pxToRem(130);
  }
}
