@import "../../mixins/type/index";

/* Base Styles
 –––––––––––––––––––––––––––––––––––––––––––––––––– */
/* NOTE
 html is set to 62.5% so that all the REM measurements throughout Skeleton
 are based on 10px sizing. So basically 1.5rem = 15px :) */
html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.07rem;
  width: 100%;
  height: 100%;
}

body,
#app {
  @include type--body;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100vh;
}

// !!!! DEPRECATION WARNING FOR BELOW for sure!
// !!!!!!!
// WE DEFINITELY WANT TO DELETE ALL THE STYLES BELOW HERE
// please help us by using styles and components in our pattern library!

/* Links
 –––––––––––––––––––––––––––––––––––––––––––––––––– */
// Needed to keep for links basically everywhere [Aug 2022]
a {
  color: $colors--primary-text;
  text-decoration: none;

  &:hover {
    color: $colors--gray-banner;
    cursor: pointer;
  }
}

/* Forms
 –––––––––––––––––––––––––––––––––––––––––––––––––– */
// Needed to keep for drop downs, saw issues on shop page etc [Aug 2022]
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: 95% 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAANUlEQVQYV2NkIBIwEqmOgSSF/4kxFWYiIcWMyFbjUgxWg+5GdMVweWyegSlGkSPJ18R4mgEAzisFCpptXUQAAAAASUVORK5CYII=);
}

/* Lists
 –––––––––––––––––––––––––––––––––––––––––––––––––– */
// Needed to keep for checkboxes on shop page etc [Aug 2022]
ol,
ul {
  margin-top: 0;
  padding-left: 0;
}

// this powers isMobile(); [Aug 2022]
// please remove it from powering isMobile()
// or better yet -- stop using isMobile()!
@media only screen and (min-device-width: 0px) and (max-device-width: 1058px) and (-webkit-min-device-pixel-ratio: 2) {
  .mobile-detector {
    display: none;
  }
}
