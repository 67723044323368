.listingAge {
  line-height: 14px;
  color: var(--colors--secondary-text);
  border-bottom: 1px solid var(--colors--light-stroke);
  margin-bottom: 10px;
  margin-top: 0px;
  padding: 6px 0 6px 8px;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
}

.dateAgo:first-child {
  margin-right: 5px;
}

.strikeThrough {
  margin-left: 5px;
  text-decoration: line-through;
  padding: 0 3px;
}
