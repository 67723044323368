////////////////////////////////////////////////////////////////////////////////
//
//  DEPRECATION WARNING
//
//  The mixins defined here have been deprecated in favor of using the new
//  breakpoints outlined in `app/stylesheets/mixins/media-queries`.
//
//  If you need to continue to use these (or any other) breakpoints, please use
//  the `larger-than($size)` mixin.
//
////////////////////////////////////////////////////////////////////////////////

// used the approach here: https://davidwalsh.name/write-media-queries-sass

$phone-width: 667px;
$desktop-width: 1024px;

@mixin phone {
////////////////////
//   DEPRECATED   //
////////////////////
  @media
    only screen and (min-width: 0)
    and (max-width: #{$phone-width}) {
    @content;
  }
}

// TODO/NOTE - the tablet max-width collides with the desktop min width,
// meaning that a browser at exactly 1024px width (common) will be targeted by
// both the desktop and tablet queries. We cannot change the query directly
// right now because of issues on landscape iPads
@mixin tablet {
////////////////////
//   DEPRECATED   //
////////////////////
  @media
    only screen and (min-width: #{$phone-width + 1px})
    and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin phone-and-tablet {
////////////////////
//   DEPRECATED   //
////////////////////
  @include phone {
    @content;
  }
  @include tablet {
    @content;
  }
}

@mixin desktop {
////////////////////
//   DEPRECATED   //
////////////////////
  @media only screen and (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin retina {
////////////////////
//   DEPRECATED   //
////////////////////
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin print {
////////////////////
//   DEPRECATED   //
////////////////////
  @media print {
    @content;
  }
}
