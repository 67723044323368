@import "../../mixins/media-queries/index";

.empty-conversation-disclaimer {
  margin: 0 auto;
  margin-top: 30px;
  width: 84%;
  font-size: 14px;
  line-height: 24px;
  color: $colors--gray-banner;

  @include larger-than($phone-width) {
    width: 50%;
  }
}
