@import "../../../shared/fill_available";

.conversation-item-mobile-chat {
  $convo-links-border-color: $colors--primary-text;

  @include fill-available-height;
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;

  @import "./conversation-listing-status-bar";
  @import "./interlocutor";
  @import "./conversation-item-activity-log";
  @import "./conversation-links-wrapper";

  // HACK: Ideally, when there are no conversation links, the conversation links
  // element will be hidden, not in the DOM, or empty. That isn't the case yet,
  // so here's a workaround. [Evan 2017-10-14]
  .conversation-item-activity-log + .conversation-links-wrapper {
    .conversation-links {
      gap: 10px;
      button, a {
        width: 100%;
      }
      .--button {
        border-top: 1px solid $convo-links-border-color;
        border-bottom: 1px solid $convo-links-border-color;
        font-weight: $type--weights--bold;

        &._contact {
          background-color: $colors--active;
          color: $colors--white-background;
        }
      }
    }
  }
}
