@import "../type/index";

@mixin tooltip {
  .react-tooltip-lite {
    @include type--font-size-and-line-height(13px);
    background: var(--colors--active);
    border-radius: 2px;
    color: var(--colors--white-background);
  }

  .react-tooltip-lite-up-arrow {
    border-top: 10px solid var(--colors--active);
  }

  .react-tooltip-lite-down-arrow {
    border-bottom: 10px solid var(--colors--active);
  }

  .react-tooltip-lite-right-arrow {
    border-right: 10px solid var(--colors--active);
  }

  .react-tooltip-lite-left-arrow {
    border-left: 10px solid var(--colors--active);
  }

  &._black-tooltip {
    .react-tooltip-lite {
      background: var(--colors--active);
    }

    .react-tooltip-lite-up-arrow,
    .react-tooltip-lite-down-arrow,
    .react-tooltip-lite-right-arrow,
    .react-tooltip-lite-left-arrow {
      border-color: var(--colors--active);
    }
  }

  &._black-border-tooltip {
    :global(.react-tooltip-lite) {
      background: var(--colors--white-background);
      border: 1px solid var(--colors--active);
      color: var(--colors--accent);
    }

    :global(.react-tooltip-lite-up-arrow),
    :global(.react-tooltip-lite-down-arrow),
    :global(.react-tooltip-lite-right-arrow),
    :global(.react-tooltip-lite-left-arrow) {
      border-color: var(--colors--accent);
    }

    :global(.react-tooltip-lite-arrow)::after {
      content: "";
      position: absolute;
      border: 10px solid transparent;
    }

    :global(.react-tooltip-lite-up-arrow)::after {
      border-top: 10px solid var(--colors--white-background);
      top: -11px;
      left: -10px;
    }

    :global(.react-tooltip-lite-down-arrow)::after {
      border-bottom: 10px solid var(--colors--white-background);
      top: -9px;
      left: -10px;
    }

    :global(.react-tooltip-lite-right-arrow)::after {
      border-right: 10px solid var(--colors--white-background);
      top: -10px;
      left: -9px;
    }

    :global(.react-tooltip-lite-left-arrow)::after {
      border-left: 10px solid var(--colors--white-background);
      top: -10px;
      left: -11px;
    }
  }

  &._blue-tooltip {
    .react-tooltip-lite {
      background: var(--colors--blue-highlight);
    }

    .react-tooltip-lite-up-arrow,
    .react-tooltip-lite-down-arrow,
    .react-tooltip-lite-right-arrow,
    .react-tooltip-lite-left-arrow {
      border-color: var(--colors--blue-highlight);
    }
  }

  &._white-tooltip {
    :global(.react-tooltip-lite) {
      border: 1px solid var(--colors--light-stroke);
      background: var(--colors--white-background);
      color: var(--colors--accent);
    }

    :global(.react-tooltip-lite-arrow) {
      border-color: var(--colors--light-stroke);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        z-index: 99;
        display: block;
      }
    }

    :global(.react-tooltip-lite-up-arrow)::before {
      border-top: 10px solid var(--colors--white-background);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      left: -10px;
      top: -11px;
    }

    :global(.react-tooltip-lite-down-arrow)::before {
      border-bottom: 10px solid var(--colors--white-background);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      left: -10px;
      bottom: -11px;
    }

    :global(.react-tooltip-lite-right-arrow)::before {
      border-right: 10px solid var(--colors--white-background);
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      right: -11px;
      top: -10px;
    }

    :global(.react-tooltip-lite-left-arrow)::before {
      border-left: 10px solid var(--colors--white-background);
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      left: -11px;
      top: -10px;
    }
  }

  &._gray-tooltip {
    .react-tooltip-lite {
      background: var(--colors--off-white-background);
      color: var(--colors--accent);
    }

    .react-tooltip-lite-up-arrow,
    .react-tooltip-lite-down-arrow,
    .react-tooltip-lite-right-arrow,
    .react-tooltip-lite-left-arrow {
      border-color: var(--colors--off-white-background);
    }
  }
}
