@mixin disclaimer {
  &._visibility {
    line-height: normal;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    .-icon {
      background-image: url("https://media-assets.grailed.com/prd/misc/RFB7KR3BX00UFILWZXH-DBTS9B-LCNOWLLJWQG");
      height: 10px;
      width: 12px;
      min-width: 12px;
      background-repeat: no-repeat;
    }
    .-message {
      font-size: 12px;
      color: var(--colors--secondary-text);
      line-height: normal;
      margin-bottom: var(--spacing-4);
    }

    .-icon + .-message {
      margin-left: 5px;
    }
  }
}
