@import "mixins/type/index";
@import "mixins/media-queries/index";

.mobileFooter {
  @include type--primary-link;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
  align-items: center;
  justify-content: space-evenly;
  height: 60px;

  .break {
    flex-basis: 100%;
    height: 0;
  }

  @include larger-than-phone {
    padding: 0 20px;
    justify-content: space-between;

    .break {
      display: none;
    }
  }

  @include larger-than-laptop {
    display: none;
  }

  .siteLink {
    font-weight: 600;
  }
}
