@import "mixins/type/index";
@import "mixins/media-queries/index";

.siteLink {
  @include type--primary-link;
  display: none;

  & + & {
    margin-left: 48px;
  }

  @include larger-than-laptop {
    display: inline-block;
  }

  &.isMobile {
    @include type--primary-link;
    display: inline-block;

    & + & {
      margin-left: 16px;
    }

    @include larger-than-laptop {
      display: none;
    }
  }
}
