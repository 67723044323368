@import "../../mixins/accordion";

.sell-info {
  flex-wrap: wrap;

  .-how-to-sell-guide-link {
    @include type--super-link;
  }

  .sell-point {
    box-sizing: border-box;
    display: flex;
    padding: 5px 0;
    width: 100%;

    &:first-of-type {
      padding-top: 12px;
    }

    &:last-of-type {
      padding-bottom: 12px;
    }

    @include larger-than-tablet {
      padding: 0 30px 30px 15px;
      width: 50%;

      &:first-of-type {
        padding-top: 0;
      }
    }

    .--icon {
      margin: 3px 20px 0 0;
      width: 14px;

      @include larger-than-tablet {
        width: 22px;
        min-width: 22px;
      }
    }

    .info {
      @include type--font-size-and-line-height(12px);

      .--title {
        font-weight: $type--weights--heavy;
        margin-right: 3px;

        @include larger-than-tablet {
          @include type--title;
          display: block;
        }

        ._mobile-only {
          @include larger-than-tablet {
            display: none;
          }
        }
      }

      .--content {
        @include larger-than-tablet {
          display: block;
          margin-bottom: 0;
        }
      }
    }
  }

  &._title {
    @include type--headline-2;
    display: none;

    @include larger-than-phone {
      display: block;
    }
  }

  &._card {
    display: none;

    @include larger-than-phone {
      margin-top: 2em;
      display: flex;
    }
  }

  &._accordion {
    @include accordion;
    box-sizing: border-box;
    margin-bottom: 20px;
    overflow: hidden;
    padding: 10px 15px 0 15px;
    width: 100%;
    border: 1px solid $colors--light-stroke;

    @include larger-than-phone {
      display: none;
    }

    .--accordion {
      border-bottom: 0;
      margin-bottom: -1px;
      padding-bottom: 0;

      .--accordion-label {
        @include type--font-size-and-line-height(12px);
        border-bottom: 1px solid $colors--light-stroke;
        display: block;
        padding-bottom: 7px;
        z-index: 1;
      }

      .--accordion-caret {
        margin-top: 0;
        position: absolute;
        right: 0;
        top: 7px;
      }

      .--accordion-content {
        padding: 0;
      }
    }
  }
}
