@mixin sellform-photo-spinner {
  background: var(--colors--white-background);
  display: flex;
  align-items: center;
  justify-content: center;
  .loading { margin-top: -24px; }

  &::after {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1px solid;
    border-left-color: transparent;
    color: inherit;
    animation: rotate 2s forwards linear infinite;

    @keyframes rotate {
      100% { transform: rotate(360deg); }
    }
  }
}
