@import "../../../mixins/button/index";

.Editorial--ProfileHeaderModule {
  position: relative;

  .-hero {
    height: 300px;
    width: 100%;
    background-position: center;
    background-size: cover;
    @include larger-than-tablet {
      height: 500px;
      background-attachment: fixed;
    }

    &::before {
      position: absolute;
      content: " ";
      top: 0;
      width: 100%;
      height: 300px;
      background-color: $colors--primary-text;
      opacity: 0.5;

      @include larger-than-tablet {
        height: 500px;
      }
    }
  }

  .-hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 300px;
    width: 100%;
    position: absolute;
    top: 0;

    @include larger-than-tablet {
      height: 500px;
    }

    .-logo {
      height: 50px;
      width: 100px;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .-dropped-text {
      @include type--primary-link;
      @include type--font-size-and-line-height(35px);
      color: $colors--white-background;
    }

    .-countdown {
      @include heatwave--countdown;

      .-header {
        @include type--primary-link;
        color: $colors--white-background;
        @include type--font-size-and-line-height(14px);
        font-weight: $type--weights--heavy;
      }
    }
  }

  .-profile {
    width: 100%;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;
    padding: 0 30px 30px 30px;
    transform: translate(0, -80px);
    border-bottom: 1px solid $colors--light-stroke;

    @include larger-than-tablet {
      flex-direction: row;
      align-items: flex-start;
      transform: translate(0);
      padding: 0 10px;
      height: 180px;
      margin-bottom: 80px;
    }

    .-profile-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include larger-than-tablet {
        flex-direction: row;
        align-items: flex-start;
      }

      .-profile-picture {
        height: 150px;
        width: 150px;
        border-radius: 100%;
        border: 5px solid $colors--white-background;
        background-size: cover;
        background-position: center;
        flex-shrink: 0;

        @include larger-than-tablet {
          width: 200px;
          height: 200px;
          transform: translate(0, -50%);
          margin-right: 20px;
        }
      }

      .-profile-info {
        text-align: center;
        margin-top: 20px;

        @include larger-than-tablet {
          text-align: left;
        }

        .-name-and-flair {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          justify-content: center;

          @include larger-than-tablet {
            justify-content: flex-start;
          }

          .-name {
            @include type--headline-2;
            position: relative;
            margin-bottom: 0;
          }

          .--tooltip {
            margin-left: 5px;
          }

          .-flair {
            width: 20px;
            height: 20px;
            background-image: url("https://media-assets.grailed.com/prd/misc/BO0LC4RXJNCQC4XEXRO8KQYVOGTBW49OM2NNDQ");
            background-size: contain;
            background-repeat: no-repeat;
          }
        }

        .-location,
        .-biography {
          color: $colors--secondary-text;
          @include type--font-size-and-line-height(16px);
        }

        .-location svg {
          height: 12px;
          margin-right: 5px;
        }
      }
    }

    .-follow {
      @include button;
      margin-top: 20px;
      padding: 10px 25px;
      @include type--font-size-and-line-height(16px);
    }
  }
}
