@import "../../shared/z-index";

.display-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-one {
  flex: 1;
}

.active-indicator {
  display: flex;
  align-items: center;
  padding: 5px 12px 5px 12px;
  cursor: pointer;

  .indicator {
    border-radius: 2px;
    box-sizing: border-box;

    width: 12px;
    height: 12px;
    margin-right: 6px;
    padding: 2px;
    background-color: $colors--white-background;
    background-clip: content-box;
    border: 1px solid $colors--light-stroke;
  }

  h3 {
    margin-bottom: 0;

    &.middle {
      margin-right: auto;
      margin-bottom: 1px;
      text-transform: capitalize;
    }
    &.small {
      letter-spacing: 0.4px;
      margin-bottom: 0;
    }
  }

  &:hover {
    .indicator {
      border: 2px solid $colors--light-stroke;
      padding: 1px;
    }
  }

  &.active {
    h3 {
      font-weight: bold;
    }
    .indicator {
      background-color: $colors--primary-text;
    }
  }
}

.unread {
  color: $colors--white-background;
  background-color: $colors--alert;
  font-weight: bold;
  padding: 2px;
  margin-left: 3px;
  display: inline-block;
  min-width: 16px;
  box-sizing: border-box;
  text-align: center;
  letter-spacing: 1px;
}

.drop-down {
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  cursor: pointer;
  text-align: right;

  h3.drop-down-title {
    position: relative;
    z-index: $z-drop-down-title;

    &.active {
      font-weight: bold;
    }
  }

  .drop-down-title {
    &::selection {
      background-color: transparent;
    }
    &::moz-selection {
      background-color: transparent;
    }
    &::webkit-selection {
      background-color: transparent;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .drop-down-toggle {
    background-color: $colors--white-background;
    text-align: center;
    position: absolute;
    display: none;
    z-index: $z-drop-down-toggle;
    padding: 35px 20px 8px;
    border: 1px solid $colors--tertiary-text;
  }

  &.expanded {
    .drop-down-toggle {
      display: block;
      top: -5px;
      right: 0;
    }
    background-color: $colors--white-background;

    transition-property: all;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    opacity: 1;

    .drop-down-toggle h3 {
      margin-bottom: 0;
      padding: 0.5rem 0;
      &.selected,
      &:hover {
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}

.banned {
  color: $colors--alert !important;
  text-decoration: line-through !important;
}

.deleted {
  font-style: italic !important;
}

.blocked {
  opacity: 0.3 !important;
}

.columns {
  display: flex;
  .column {
    flex: 1;
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.vertical-choice,
.horizontal-choice {
  display: flex;
  justify-content: center;

  a,
  h3 {
    font-weight: bold;
    color: $colors--gray-banner;
    &:hover,
    &.selected {
      color: $colors--primary-text;
    }
  }

  h3 {
    margin-bottom: 0;
    cursor: pointer;
  }
}

.horizontal-choice {
  padding: 20px 0;

  a {
    transition: 0.1s ease-in color;
    margin-right: 5rem;

    &:hover,
    &.selected {
      h3 {
        color: $colors--primary-text;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.vertical-choice {
  flex-direction: column;
  align-items: center;

  a {
    margin-bottom: 1rem;

    &:hover {
      h3 {
        font-weight: bold;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem;
}

.triangle {
  background: url("https://media-assets.grailed.com/prd/misc/0YUJP3YCGITMZNNY8IJ7WV5MPPR2-JV4WRAVWG")
    50% no-repeat;
  background-size: contain;
  width: 9px;
  height: 5px;
  display: inline-block;
  margin-left: 4px;

  &:not(.expanded) {
    transform: rotate(-90deg);
  }

  &.expanded {
    margin-left: 3px;
    transform: rotate(0deg);
  }
}

.tooltip {
  position: absolute;
  background-color: $colors--white-background;
  padding: 5px 10px;
  text-align: left;
  border: 1px solid $colors--secondary-text;
  margin-bottom: 0;
  font-size: 10px;

  .arrow {
    display: block;
    position: absolute;
    width: 0;
    height: 0;

    &.bottom-left {
      bottom: -1px;
      left: -13px;

      border-style: solid;
      border-width: 0 13px 13px 0;
      border-color: transparent $colors--secondary-text transparent transparent;
    }
    &.top-left {
      top: -1px;
      left: -13px;
      border-style: solid;
      border-width: 13px 13px 0px 0px;
      border-color: transparent $colors--secondary-text transparent transparent;
    }
  }
}

.vertical-align-wrapper {
  display: flex;
  align-items: center;
}

@media only screen and (min-device-width: 0px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .unread {
    font-size: 14px;
    font-weight: normal;
    top: -4px;
    position: absolute;
    right: -10px;
    padding: 0;
    line-height: 1.43;
    border: 1px solid white;
  }

  div.tabbed-nav {
    .tabs {
      display: flex;
      border-bottom: 1px solid $colors--tertiary-text;
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .tab {
      flex-grow: 1;
      cursor: pointer;
      letter-spacing: 0;
      margin-bottom: 0;
      padding: 16px 5px;
      font-size: 1rem;
      text-transform: uppercase;

      &.active {
        font-weight: 600;
      }
    }
  }
}
