@import "../../mixins/field/index";
@import "../../mixins/type/index";
@import "../../mixins/checkbox/index";

body > .container #notifications {
  margin: 0 25px;
}

#notifications {
  .Notifications {
    .-settings {
      padding-bottom: 5rem;

      .NotificationItem:nth-child(2n) {
        background-color: $colors--off-white-background;
      }
    }

    .-category + .-category {
      margin-top: 3.5rem;
    }

    .-category-title {
      @include type--title;
      color: $colors--secondary-text;
      padding-left: 2rem;
    }

    .-category + .-unsubscribe-all {
      margin-top: 3.5rem;
    }

    .-unsubscribe-all {
      color: $colors--secondary-text;
      padding-left: 2rem;
    }

    .-unsubscribe-button {
      display: inline;
      padding: 0;
      margin: 0;
      text-align: initial;
      font-size: inherit;
      font-weight: inherit;
      letter-spacing: inherit;
      text-transform: inherit;
      text-decoration: inherit;
      white-space: initial;
      border-radius: 0;
      cursor: pointer;
      box-sizing: border-box;
      line-height: inherit;
      color: $colors--blue-highlight;
      background-color: transparent;
      border: 0;
      font-family: inherit;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      box-shadow: none;
    }
  }

  .NotificationItem {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;

    @include larger-than-tablet {
      flex-direction: row;
    }

    .-details {
      width: 100%;

      @include larger-than-tablet {
        width: 60%;
      }
    }

    .-details + .--checkbox {
      margin-top: 2rem;

      @include larger-than-tablet {
        margin-top: 0;
        margin-left: 5rem;
      }
    }

    .-title {
      @include type--subtitle;
    }

    .-description {
      color: $colors--secondary-text;
      margin-bottom: 0;

      .-link {
        color: $colors--blue-highlight;
      }
    }

    .--checkbox {
      @include field;
      @include checkbox;
      width: 100%;
      margin-left: 2rem;

      @include larger-than-tablet {
        width: 20%;
      }
    }

    .--checkbox + .--checkbox {
      margin-top: 2rem;

      @include larger-than-tablet {
        margin-top: 0;
        margin-left: 5rem;
      }
    }
  }
}
