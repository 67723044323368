@import "../../../mixins/media-queries/index";

.conversation-settings {
  $svg-fill-color: $colors--medium-stroke;
  $window-border-color: $colors--primary-text;
  $window-shadow-color: rgba(0, 0, 0, 0.5);
  $window-background-color: $colors--white-background;

  display: none;

  @include larger-than($phone-width) {
    display: block;
  }

  .settings-cog {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    padding: 5px;
    cursor: pointer;

    svg {
      width: 13px;

      path {
        fill: $colors--secondary-text;
      }
    }
  }

  .settings-window {
    position: absolute;
    right: 30px;
    top: 5px;
    padding: 1.5rem;
    border-radius: 2px;
    border: 1px solid $window-border-color;
    box-shadow: 0px 1px 4px $window-shadow-color;
    background-color: $window-background-color;
    text-align: center;
    z-index: $z-settings-window;
    cursor: default;
  }
}
