@import '../../../mixins/type/index';
@import '../../../mixins/grid/index';
@import './mixins';

@keyframes slide {
  0% { transform: translate3d(0, 0, 0); }
  100% { transform: translate3d(-6550px, 0, 0); } // 1x image width
}

.Editorial--ShopMoreModule {
  text-align: center;
  margin-bottom: 6rem;
  padding-top: 6rem;
  border-top: 1px solid $colors--light-stroke;

  &._LilYachty, &._SocialStudies {
    .-cta {
      .-cta-button {
        box-shadow: none;

        &:hover, &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .-title {
    @include heatwave--type-section-label;
    margin: 0 1rem 2rem;
  }

  .-message {
    @include heatwave--type-medium;
    margin: 0 1rem 3rem;

    @include larger-than-tablet {
      margin: 0 auto 3rem;
      width: 60%;
    }
  }

  .-cta {
    position: relative;
    height: 300px;
    padding-bottom: 3rem;
    overflow: hidden;

    .-image {
      height: 100%;
      width: 5978px; // not full width, will jump on mobile but needed for mobile browsers
      background-repeat: repeat-x;
      background-size: contain;
      animation: slide 90s linear infinite;

      @include larger-than-tablet {
        width: 9825px; // 1.5x image width
      }
    }

    .-cta-button {
      @include heatwave-button;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 3.5rem;
      margin: 0 auto;

      @include larger-than-tablet {
        width: 32%;
        bottom: 1rem;
      }
    }
  }
}
