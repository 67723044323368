// PATTERN LIBRARY COLORS
// Main
$colors--primary-text: #000000;
$colors--gray-banner: #343434;
$colors--secondary-text: #737373;
$colors--tertiary-text: #b3b3b3;
$colors--medium-stroke: #b3b3b3;
$colors--light-stroke: #e1e1e1;
$colors--off-white-background: #fafafa;
$colors--white-background: #ffffff;
$colors--blue-highlight: #0000ff;
$colors--accent: #000000;
$colors--active: #000000;
$colors--seafoam-green: #208368; // convo purchase green
// Alerts
$colors--info: #007aff;
$colors--success: #208368;
$colors--warning: #f59a05;
$colors--alert: #d0021b;
$colors--hover-alert: #f2001d;
// Feedback
// low feedback color is colors--alert
$colors--feedback-medium: #8ca482;
$colors--feedback-high: #009c00;
// Tints
$colors--success-tint-green: #f8fcfb;
$colors--warning-tint: #fefbf7;
$colors--new-tint: #f7fbff;
$colors--bad-tint: #fdf7f8;
$colors--important-tint: #f7f7ff;
// Other Products
$colors--paypal-dark-blue: #012169;
$colors--paypal-light-blue: #009cde;
$colors--facebook-blue: #3b5998;

body._grailed {
  // Main
  --colors--primary-text: #{$colors--primary-text};
  --colors--gray-banner: #{$colors--gray-banner};
  --colors--secondary-text: #{$colors--secondary-text};
  --colors--tertiary-text: #{$colors--tertiary-text};
  --colors--medium-stroke: #{$colors--medium-stroke};
  --colors--light-stroke: #{$colors--light-stroke};
  --colors--off-white-background: #{$colors--off-white-background};
  --colors--white-background: #{$colors--white-background};
  --colors--blue-highlight: #{$colors--blue-highlight};
  --colors--accent: #{$colors--accent};
  --colors--active: #{$colors--active};
  --colors--seafoam-green: #{$colors--seafoam-green};
  // Alerts
  --colors--info: #{$colors--info};
  --colors--success: #{$colors--success};
  --colors--warning: #{$colors--warning};
  --colors--alert: #{$colors--alert};
  --colors--hover-alert: #{$colors--hover-alert};
  // Feedback
  --colors--feedback-medium: #{$colors--feedback-medium};
  --colors--feedback-high: #{$colors--feedback-high};
  // Tints
  --colors--success-tint-green: #{$colors--success-tint-green};
  --colors--warning-tint: #{$colors--warning-tint};
  --colors--new-tint: #{$colors--new-tint};
  --colors--bad-tint: #{$colors--bad-tint};
  --colors--important-tint: #{$colors--important-tint};
  // Other products
  --colors--paypal-dark-blue: #{$colors--paypal-dark-blue};
  --colors--paypal-light-blue: #{$colors--paypal-light-blue};
  --colors--facebook-blue: #{$colors--facebook-blue};
}
