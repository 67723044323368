.conversation {
  padding-bottom: 0;
}

._fullHeight {
  height: 100vh;
}

@import "./conversation/shipment-form";
@import "./conversation/conversation-item-mobile-chat/index";
