@import "../../mixins/media-queries/index";

.account-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 30px;

  @include larger-than-phone {
    padding: 0 25px;
  }

  #conversations & {
    margin-top: 0;

    @include larger-than-phone {
      margin-top: 30px;
    }
  }

  .menu,
  #account-sections {
    display: none;
    width: 18.333333333%;

    @include larger-than($desktop-width) {
      display: block;
    }
  }

  .--content {
    width: 100%;

    @include larger-than($desktop-width) {
      width: 81.666666667%;
    }
  }
}
