@import "../../mixins/type/index";
@import "../../mixins/loading";
@import "../../mixins/media-queries/index";

.Homepage--Modules-Loading {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;

  .-title {
    @include type--title;
    text-align: left;
    margin-left: 0;
    margin-bottom: 20px;
    line-height: 30px;

    @include larger-than-phone {
      font-size: 20px;
    }
  }

  .-container {
    display: flex;
    height: 330px;
    width: 100%;
    overflow-x: hidden;
    gap: 10px;

    .-item {
      @include loading(100%, 330px);
      height: 100%;
      width: 100%;
      min-width: 170px;
      display: inline-block;

      @include larger-than-tablet {
        min-width: 192px;
      }
    }
  }
}
