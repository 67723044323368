@import "../../../app/assets/stylesheets/shared/z-index";
@import "mixins/media-queries/index";

.flashContainer {
  position: sticky;
  top: 49px;
  z-index: $z-flash-message;
  width: 100%;
  box-sizing: unset;

  @include larger-than-phone {
    top: 76px;
  }

  &.stickyNavBar {
    @include larger-than-tablet {
      top: 121px;
    }
  }

  &.noNavBar {
    top: 0;
  }

  &.mobileConversation {
    // needs to be explicit because this is the breakpoint for the mobile conversation view
    // it is not stored in a variable currently
    @media (max-width: 1058px) {
      top: 84px;
      position: fixed;
    }
  }
}
