.recaptcha > :first-child > :first-child {
  margin: 0 auto 10px;
}

.recaptcha iframe {
  max-height: 78px;
  background: unset;
}

.recaptcha + button {
  margin-top: 20px;
}
