@import "../../../mixins/media-queries/index";

.conversation-listing-details-wrapper {
  $title-color: $colors--primary-text;

  @include larger-than-phone {
    width: 30%;
  }

  .conversation-listing-image {
    display: block;
    flex: 0 0 60px;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    margin: 5px 13px 5px 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include larger-than($phone-width) {
      margin-right: 30px;
    }
  }

  .conversation-listing-image + div {
    width: calc(100% - 60px);
  }

  h2 {
    margin: 0;
    font-weight: 500;

    font-size: 14px;
    line-height: 24px;
    letter-spacing: normal;
    text-transform: none;
    color: $title-color;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;

    @include larger-than($phone-width) {
      font-weight: 600;
      text-transform: uppercase;
      max-width: unset;
    }
  }

  h2 + .sub-title {
    @include larger-than($phone-width) {
      margin-top: 5px;
    }
  }

  .sub-title {
    display: none;
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include larger-than($phone-width) {
      display: block;
    }
  }

  .conversation-listing-details {
  }

  .conversation-listing-link {
    display: flex;
    width: 70%;

    @include larger-than($phone-width) {
      // HACK: Doing this until we have an opportunity to refactor the HTML.
      // Designed to target the container for the h2 and h3.sub-title elements.
      // [Evan 2017-10-06]
      .conversation-listing-image + div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 1%;
      }
    }
  }
}
