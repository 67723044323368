@mixin checkbox {
  cursor: pointer;
  display: block;
  margin: 0;
  padding-left: 22px;
  position: relative;
  user-select: none;

  .--label {
    color: var(--colors--primary-text);
    cursor: pointer;
    font-weight: normal;
    padding-left: 0;
  }

  .--checkbox-input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    &:checked ~ .--checkmark {
      border: 1px solid var(--colors--primary-text);
    }

    &:checked ~ .--checkmark:after {
      display: block;
    }
  }

  .--checkmark {
    background-color: var(--colors--white-background);
    border-radius: 4px;
    border: 1px solid var(--colors--medium-stroke);
    box-sizing: border-box;
    height: 15px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 15px;

    &:after {
      background-color: var(--colors--primary-text);
      border-radius: 2px;
      content: "";
      display: none;
      height: 9px;
      transform: translate(2px, 2px);
      width: 9px;
    }

    &:hover {
      border: 2px solid var(--colors--medium-stroke);
    }
  }

  &.--check-icon {
    .--checkbox-input:checked ~ .--checkmark {
      background-color: var(--colors--primary-text);
    }

    .--checkmark:after {
      border: solid var(--colors--white-background);
      border-width: 0 2px 2px 0;
      height: 8px;
      left: 9px;
      top: 5px;
      width: 4px;
      transform: rotate(45deg) translate(3px, -2px);
    }
  }

  &.--checkbox-button {
    padding: 0;

    .--label {
      @include type--primary-link;
      @include type--font-size-and-line-height(12px);
      border: 1px solid var(--colors--primary-text);
      border-radius: 2px;
      cursor: pointer;
      display: inline-block;
      height: 100%;
      margin-top: 0;
      padding: 9px 18px 9px 40px;
      transform: none;
      transition: background-color 250ms ease, border-color 250ms ease;
      width: auto;

      &:hover {
        background-color: var(--colors--off-white-background);
      }
    }
    .--checkbox-input:checked ~ .--checkmark {
      background-color: transparent;
      border-color: transparent;
    }

    .--checkbox-input:checked ~ .--checkmark:after {
      background-color: var(--colors--white-background);
    }

    .--checkbox-input:checked ~ .--label {
      border-color: var(--colors--success);
      color: var(--colors--success);
    }

    .--checkmark {
      background: var(--colors--off-white-background);
      height: 20px;
      left: 10px;
      pointer-events: none;
      top: 50%;
      transform: translateY(-50%);
      transition: background-color 250ms ease, border-color 250ms ease;
      width: 20px;

      &:after {
        border-bottom: solid var(--colors--success);
        border-right: solid var(--colors--success);
        height: 10px;
        transform: rotate(45deg) translate(5px, -3px);
        width: 5px;
      }
    }
  }

  &._switch {
    padding: 0;
    display: flex;
    align-items: center;

    .--checkbox-input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .--slider {
      width: 50px;
      height: 30px;
      cursor: pointer;
      background-color: var(--colors--light-stroke);
      border-radius: 30px;

      &:before {
        -webkit-transition: background-color 0.2s, transform 0.2s;
        transition: background-color 0.2s, transform 0.2s;
        content: "✕";
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--colors--secondary-text);
        height: 30px;
        width: 30px;
        border-radius: 50%;
        color: var(--colors--white-background);
        font-size: 14px;
      }
    }

    .--checkbox-input:checked + .--slider:before {
      -webkit-transform: translateX(20px);
      transform: translateX(20px);
      content: "✓";
      font-size: 16px;
      background-color: var(--colors--success);
      color: var(--colors--white-background);
    }
  }
}
