.dry-clean-only-preview-wrapper {

  height: 533px;
  margin-bottom: 40px;

  position: relative;
  z-index: $z-base-value;

  .featured-article,
  .tertiary-articles {
    position: relative;

    .article {

      display: block;

      h2, h3 {
        position: absolute;
        color: $colors--white-background;
        margin-bottom: 0;
      }
      h2 {
        font-weight: bold;
      }

      &.feature {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        h2, h3 {
          left: 30px;
        }

        h2 {
          top: 390px;
          line-height: 42px;
          font-size: 36px;
          right: 40px;
        }
        h3 {
          top: 365px;
        }
      }

      &.tertiary {
        position: relative;
        height: 266px;
        margin-bottom: 1px;
        &:last-child {
          margin-bottom: 0;
        }

        h2, h3 {
          left: 30px;
        }

        h2 {
          top: 173px;
          font-size: 18px;
          font-weight: bolder;
          line-height: 30px;
        }
        h3 {
          top: 156px;
        }
      }
    }
  }

  .featured-article {
    width: 799px;
    margin-right: 1px;
  }

}
