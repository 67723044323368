.fill {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.crop {
  object-fit: cover;
}

.clip {
  object-fit: contain;
}

.scale {
  object-fit: fill;
}

.top {
  object-position: top;
}

.bottom {
  object-position: bottom;
}

.left {
  object-position: left;
}

.right {
  object-position: right;
}

.center {
  object-position: center;
}
