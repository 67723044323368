@import "mixins/type/index";

.errorPage {
  padding: 5%;
  text-align: center;

  .title,
  .statusCode {
    @include type--headline-1;
    color: var(--colors--primary-text);
  }

  .statusCode + .title {
    &:before {
      content: " – ";
    }
  }

  .heading {
    margin: 0;
    text-align: center;
  }

  .heading + .image {
    margin-top: 20px;
  }

  .heading + .contact {
    margin-top: 15px;
  }

  .contact {
    @include type--base;
    @include type--font-size-and-line-height(14px);
    color: var(--colors--primary-text);
    text-align: center;
  }

  .contact + .image {
    margin-top: 20px;
  }

  .image {
    width: 100%;
    max-width: 350px;
  }
}
