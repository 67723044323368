.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  text-align: center;

  .icon {
    height: 80px;
    width: 88px;
  }
  .icon + .body {
    margin-top: 3rem;
  }

  .body {
    margin: 0;

    .link {
      width: unset;
      height: unset;
      font-size: unset;
      text-decoration: underline;
      color: unset;
      cursor: pointer;
    }
  }
  .body + .body {
    margin-top: 1.5rem;
  }
  .body + .button {
    margin-top: 2rem;
  }

  .button {
  }
  .button:hover {
    color: var(--colors--white-background);
  }
  .button + .body {
    margin-top: 3rem;
  }
}
