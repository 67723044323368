@import "../../../../../mixins/type/index";
@import "../../../../../mixins/media-queries/index";
@import "../../../../../mixins/button/index";
@import "../../mixins";

.Editorial--DropProductsModule--Active--SoldOut {
  position: relative;
  @include heatwave-modal;
  padding: 3rem 6rem 6rem;
  background: $colors--white-background;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &._LilYachty, &._SocialStudies {
    box-shadow: none;
    border: 4px solid $colors--primary-text;
  }

  .-title {
    @include heatwave--type-medium;
    margin-bottom: 3rem;
  }

  .-image {
    margin-bottom: 1rem;
    max-height: initial;
    max-width: 100%;

    @include larger-than-phone {
      max-width: 80%;
    }
  }

  .-cta {
    @include button;
    position: absolute;
    bottom: -3rem;
    left: 0;
    right: 0;
    margin: auto;

    &._x-large {
      max-width: 80%;
    }

    @include larger-than-phone {
      &._x-large {
        max-width: 50%;
      }
    }
  }

  .-subtitle {
    @include heatwave--type-body;
    color: $colors--secondary-text;
    font-size: 16px;
  }
}
