@import "../../styles/pxToRem.module.scss";

input[type="email"].Field-Control,
input[type="number"].Field-Control,
input[type="search"].Field-Control,
input[type="text"].Field-Control,
input[type="tel"].Field-Control,
input[type="url"].Field-Control,
input[type="password"].Field-Control,
textarea.Field-Control,
select.Field-Control,
.Field-Control {
  --background-color: var(--colors--white-background);

  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  border: 1px solid var(--colors--light-stroke);
  border-radius: 4px;
  font-size: pxToRem(16);
  color: var(--colors--primary-text);
  caret-color: var(--colors--info);
  background-color: var(--background-color);

  &::placeholder {
    color: var(--colors--secondary-text);
  }

  &:focus {
    border-color: var(--colors--primary-text);

    &::placeholder {
      color: transparent;
    }
  }

  &._error {
    border-color: var(--colors--alert);
  }

  &:disabled {
    --background-color: var(--colors--off-white-background);
    color: var(--colors--secondary-text);
  }
}
