@import "../mixins/type/index";

.pagination {
  @include type--pagination;
  margin: 40px auto;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  list-style-type: none;

  .-navigation {
    cursor: pointer;
    display: block;
    line-height: 0;
    padding: 15px 0px;

    &.-prev {
      margin-right: 20px;
      transform: rotate(90deg);
    }

    &.-next {
      margin-left: 20px;
      transform: rotate(-90deg);
    }

    &.-disabled {
      display: none;
    }
  }

  .-page {
    color: $colors--primary-text;
    letter-spacing: 0.04em;
    margin-left: 16px;
    margin-right: 16px;
    padding: 14px 2px;
    font-weight: $type--weights--bold;

    &.-gap {
      color: $colors--tertiary-text;
      cursor: default;
    }

    &._current {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 36px;
        left: 0px;
        right: 0px;
        border-top: 1px solid $colors--primary-text;
      }
    }
  }
}
