.displaynone {
  display: none;
}

.hidden {
  height: 0;
  visibility: hidden;
}

.bold {
  font-weight: bold !important;
}

.jumbo {
  font-size: 120%;
}
.small {
  font-size: 80%;
}

.titleize {
  text-transform: capitalize;
}

.strike-through {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.clearfix{*zoom:1;}.clearfix:before,.clearfix:after{display:table;content:"";line-height:0;}
.clearfix:after{clear:both;}

.noteworthy {
  font-size: 1.3rem;
  letter-spacing: 0.07rem;
  font-style: italic;
  margin-bottom: 20px;
}

.green {
  color: var(--colors--success);
}

.yellow {
  color: var(--colors--warning);
}

.red {
  color: var(--colors--alert);
}

.blue {
  color: var(--colors--info);
}

.slate {
  color: var(--colors--gray-banner);
}

.bold {
  font-weight: bold;
}

.align-items-center {
  align-items: center;
}

._rotate-up {
  transform: rotate(180deg);
}

._rotate-down {
  transform: rotate(0);
}

._rotate-left {
  transform: rotate(90deg);
}

._rotate-right {
  transform: rotate(270deg);
}
