.conversation-listing-status-bar {
  $background-color: $colors--bad-tint;

  padding: 15px 20px 15px;
  margin-bottom: 0px;
  background-color: $background-color;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.2em;
  letter-spacing: normal;
  text-transform: uppercase;
  text-align: center;

  &.warn {
    background-color: $colors--alert;
    color: $colors--white-background;
  }

  &.withIcon {
    display: flex;
    justify-content: center;
    align-items: center;

    .infoIcon {
      margin-left: 3px;
    }
  }

  .warning-header + .offsite-warning {
    margin-top: 18px;
  }

  .offsite-warning {
    font-size: 14px;
    line-height: 1.71em;
    letter-spacing: normal;
    text-transform: none;

    span {
      display: inline;
    }

    a {
      color: inherit;
    }
  }
}
