@import "mixins/media-queries/index";

.heart {
  transition: transform 0.3s;

  &:hover {
    transform: none;
    @include larger-than-phone {
      transform: scale(1.2);
    }
  }

  &:active {
    transform: scale(1.2);
  }
}

