.heart {
  transition: transform 0.3s;

  &.filled {
    g {
      fill: $colors--primary-text;
      stroke: $colors--primary-text;
    }
  }

  &.unfilled {
    g {
      fill: none;
      stroke: $colors--primary-text;
    }
  }

  &:hover {
    transform: none;
    @include larger-than-phone {
      transform: scale(1.2);
    }
  }

  &:active {
    transform: scale(1.2);
  }
}

.heart-follow {
  display: inherit;
  padding: 7px;
}
