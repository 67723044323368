.socialLink {
  display: inline-block;
  width: 15px;
  height: 18px;

  & + & {
    margin-left: 20px;
  }

  svg,
  img {
    width: 15px;
    height: 18px;
  }
}
