@import "../type/index";
@import "../button/index";
@import "../../../../../storefront/components/styles/pxToRem.module.scss";

@mixin field {
  position: relative;
  display: block;
  width: 345px;
  margin-bottom: 0.5rem;

  .--label {
    display: block;
    padding: 0 0 0 5px;
    margin: 0;
    @include type--form-label;
    color: var(--colors--secondary-text);
    transition-duration: 250ms;
    transition-timing-function: ease;
    transition-property: color;
  }

  .--label + .--input {
    margin-top: 5px;
  }

  .--input {
    display: block;
    box-sizing: border-box;
    padding: 10px 18px;
    width: 100%;
    height: auto;
    border: 1px solid var(--colors--light-stroke);
    border-radius: 4px;
    margin: 0;
    resize: vertical;
    background-color: var(--colors--white-background);
    background-image: none;
    @include type--body;
    transition-duration: 250ms;
    transition-timing-function: ease;
    transition-property: border-color, color, background-color;
    -webkit-appearance: none;

    &::placeholder,
    &.placeholder {
      color: var(--colors--tertiary-text);
    }
  }

  .--input + .--note,
  .--input + .--error,
  .--note + .--error {
    margin-top: 5px;
  }

  .--note {
    display: block;
    padding: 0 0 0 5px;
    @include type--form-label;
    color: var(--colors--tertiary-text);
  }

  .--note + .--error {
    margin-top: 5px;
  }

  .--error {
    display: none;
    padding: 0 0 0 5px;
    @include type--form-label;
    color: var(--colors--alert);

    &:before {
      content: "Error: ";
    }
  }

  .--icons {
    position: absolute;
    top: 25px;
    right: 15px;
    display: flex;
    align-items: center;
    height: 48px;
  }

  .--icon {
    &._toggle {
      @include type--primary-link;
      color: var(--colors--tertiary-text);
      cursor: pointer;
      transition: color 250ms ease;
    }

    &._clear {
      position: relative;
      opacity: 1;
      width: 19px;
      height: 19px;
      font-size: 0;
      cursor: pointer;
      transition: opacity 250ms ease;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: -4px;
        right: 9px;
        display: block;
        width: 1px;
        height: 26.870057685088806px; // √(19^2 + 19^2)
        background-color: var(--colors--medium-stroke);
      }

      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }

    &._clear-input {
      cursor: pointer;
      position: absolute;
      right: 4px;
    }
  }

  &._disabled {
    cursor: not-allowed;

    .--input {
      background-color: var(--colors--off-white-background);
      cursor: not-allowed;
    }
  }

  &._required {
    .--label:after {
      content: " *";
      color: var(--colors--alert);
    }
  }

  &._focused {
    .--label {
      color: var(--colors--primary-text);
    }

    .--input {
      border-color: var(--colors--primary-text);
    }

    .--icon._toggle {
      color: var(--colors--primary-text);
    }
  }

  &._empty {
    .--icon._clear {
      opacity: 0;
    }

    .--input {
      color: var(--colors--tertiary-text);
    }
  }

  &._error {
    .--input {
      border-color: var(--colors--alert);
    }

    .--note {
      display: none;
    }

    .--error {
      display: block;
    }
  }

  &._successful {
    &:after {
      content: url("https://media-assets.grailed.com/prd/misc/ZY84JZAWQHGXIZBUFORCYLCDC9WIAESSW3YALA");
      position: absolute;
      right: 20px;
      top: pxToRem(42);
    }

    .--input {
      border-color: var(--colors--success);
    }
  }

  &.--mobile-sort {
    .--input {
      @include type--large-link;
      @include type--font-size-and-line-height(12px);
      cursor: pointer;
      padding: 4px 8px 4px 26px;
      width: auto;
    }

    .--icons {
      height: 30px;
      left: 10px;
      right: auto;
    }
  }

  &.--search {
    .--input {
      border-color: var(--colors--primary-text);
      padding-left: 42px;
      padding-right: 85px;

      &:focus ~ .--icons {
        cursor: pointer;
        top: 0;

        .--icon._search {
          display: none;
        }

        .--icon._clear-input {
          display: block;
        }
      }

      &:focus ~ button {
        display: block;
      }
    }

    .--icons {
      left: 15px;
      right: auto;
      top: 0;

      .--icon {
        left: 0;
        position: absolute;
        right: auto;
      }

      .--icon._clear-input {
        display: none;
      }
    }

    button {
      @include button;
      display: none;
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    }

    &._large {
      max-width: 600px;
      width: 100%;

      .--input {
        @include type--font-size-and-line-height(16px);
        border: none;
        box-shadow: 0px 0px 4px rgba(var(--colors--primary-text), 0.25);
        padding: 23px 25px 23px 58px;
      }

      .--icons {
        left: 25px;
        top: 50%;
        transform: translateY(-50%);

        .--icon._clear-input {
          top: 51px;
        }
      }

      button {
        display: none;
      }
    }

    &._mobile {
      .--input {
        padding: 10px 85px 10px 18px;
      }

      .--icons {
        display: none;
      }
    }
  }

  select + .--icons {
    pointer-events: none;
  }

  .--photo-input {
    display: flex;
    align-items: center;
    min-width: 600px;
    flex-shrink: 0;

    .-image-input {
      position: relative;
      width: 50%;
    }

    .-loading {
      @include type--body;
      position: absolute;
      right: -100px;
      top: 30px;
    }

    .-input-button {
      position: relative;
    }

    .-input {
      opacity: 0;
      position: absolute;
      width: 100%;
      margin: 0;
      height: 100%;
      cursor: pointer;
    }

    .-upload {
      @include button;
      width: 100%;
    }

    .-image-rules {
      @include type--form-label;
      color: var(--colors--tertiary-text);
      padding: 0 0 0 5px;
      margin-bottom: 0;
    }

    .-input-button + .-image-rules {
      margin-top: 5px;
    }

    .-image-input + .-image-preview {
      margin-left: 10%;
    }
  }
}

@mixin sort-dropdown {
  @include field;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .-label {
    @include type--primary-link;
    margin-right: 10px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 4px;
      height: 12px;
      width: 13px;
    }
  }

  .-select {
    position: relative;

    .--input {
      padding-right: 30px;
    }

    .--icons {
      height: 100%;
      top: 3px;
      pointer-events: none;
    }
  }
}
