@import "../../mixins/button/index";
@import "../../mixins/type/index";
@import "../../mixins/checkbox/index";

body > .container #devices {
  margin: 0 25px;
}

#devices {
  .Devices {
    padding: 0 10px;

    @include larger-than-phone {
      padding: 0;
    }

    .-header {
      text-transform: none;
      margin-bottom: 30px;
    }

    .-devices-empty-section {
      margin-top: 40px;

      button:not(.-forget) {
        @include button;
      }
    }

    .-devices {
      padding-bottom: 5rem;

      button:not(.-forget) {
        @include button;
      }

      .-wrapper {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-bottom: 30px;

        .-actions {
          margin-left: auto;
        }

        .-header {
          margin-bottom: 0;
        }

        .-sign-out-all {
          margin-left: 10px;
        }
      }

      .-device {
        background-color: $colors--off-white-background;
        padding: 10px;
        margin-bottom: 10px;

        .-title {
          @include type--subtitle-2;
          margin-right: 5px;
        }

        .-forget {
          @include type--secondary-link;
          background: none;
          border: none;
          padding: 0;
          margin-top: 10px;
          color: var(--colors--alert);
        }
      }
    }
  }
}
