@import "mixins/media-queries/index";
@import "mixins/type/index";

.authenticationModal {
  &:global(.modal._hasHeader) {
    border: 1px solid var(--colors--light-stroke);
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    max-width: 343px;
    height: auto;
    max-height: 100%;

    @include larger-than-tablet {
      max-width: 400px;
    }

    :global {
      .Modal-Header {
        border-bottom: none;
        justify-content: left;
        padding: 0 24px;

        @include larger-than-tablet {
          padding: 0 40px;
        }

        .Modal-Header-Title {
          @include type--title;
          text-align: left;
          letter-spacing: 0;
          font-weight: $type--weights--heavy;
          font-size: 22px;
          padding-top: 34px;
          line-height: 120%;
          margin: 0;

          @include larger-than-tablet {
            font-size: 34px;
            padding-top: 46px;
          }
        }

        .-close-btn-container {
          position: absolute;
          right: 10px;
        }
      }

      .Modal-Body {
        @include type--body;
        padding: 15px 24px;

        @include larger-than-tablet {
          padding: 15px 40px 20px 40px;
        }
      }
    }
  }
}
