.shipment-form {
  h2 {
    text-transform: uppercase;
    text-align: center;
    color: $colors--gray-banner;
    font-size: 13px;
    font-family: $control-font;
  }

  form {
    width: 100%;
    flex-direction: column;
  }

  select {
    width: 100%;
    margin-bottom: 40px;
  }

  input {
    width: 100%;

    &._error {
      &::-webkit-input-placeholder {
        color: $colors--alert;
      }
    }
  }
}
