$color-sale: #be2827;
$color-original: #979797;
$color-offer: var(--colors--success);

.root {
  align-items: baseline; // attempts to fix line issue with "Video"
  display: flex;
  font-size: inherit;

  &.small {
    font-size: 13px;
    line-height: 1.8;

    .percentOff {
      font-size: 12px;
    }
  }

  &.large {
    font-size: 22px;
    line-height: 1.2;

    .percentOff {
      font-size: 14px;
    }
  }

  .onSale {
    color: $color-sale;
  }

  .offer {
    color: $color-offer;
  }

  .original {
    color: $color-original;
    position: relative;
    margin-left: 0.25em;

    &::after {
      background-color: $color-original;
      content: "";
      display: block;
      height: 0.1em;
      position: absolute;
      top: calc(50% - 0.1em);
      width: 100%;
    }
  }

  .percentOff {
    margin-left: 0.5em;
  }

  .soldLabel {
    text-transform: uppercase;
  }
}
