.carousel {
  .label {
    font-family: "Times New Roman", serif;
    font-size: 1.1rem;
    line-height: 1.1rem;
    font-style: italic;
  }

  .carousel-navigation {
    display: flex;
    align-items: center;

    span {
      background: #fff;
      opacity: 0.5;

      transition: opacity ease-in-out;
      transition-duration: 100ms;

      &:hover {
        opacity: 0.4;
      }

      &:active {
        opacity: 0.6;
      }

      svg {
        vertical-align: middle;
      }
    }
  }
}
