@import "../../mixins/media-queries/index";
@import "../../mixins/button/index";
@import "../../mixins/field/index";
@import "../../mixins/type/index";

body > .container #user_settings {
  margin: 0 25px;
}

#user_settings {
  .account-sections {
    margin-bottom: 4rem;
  }

  /**
   * The user settings content is much thinner than the space it is allowed to
   * take up within the account page frame. In order to properly center it, we need
   * to account for the space taken up on the left by the account page menu by
   * adding that much padding on the left [Evan]
   */

  .account-page .--content {
    @include larger-than($desktop-width) {
      box-sizing: border-box;
    }
  }

  .horizontal-choice {
    padding-top: 0;
    padding-bottom: 0;
  }

  .user-settings {
    text-align: center;

    &.card-management {
      text-align: left;
    }

    .header {
      margin-left: 2rem;
    }

    .--button {
      @include button;
    }
  }

  .user-settings-tabs button,
  .user-settings-tabs a {
    align-self: center;
    max-width: 350px;
    width: 100%;

    @include larger-than-phone {
      max-width: 100%;
      box-sizing: border-box;
    }
  }

  .header {
    margin-bottom: 14px;

    @include larger-than-tablet {
      text-align: left;
    }
  }

  .merchant-accounts {
    margin-bottom: 100px;
  }

  .BusinessRegistration {
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15%;
    width: auto;

    @include larger-than-tablet {
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      text-align: left;
      width: 100%;
    }
  }

  .archived-messages {
    text-align: center;
    margin-bottom: 8rem;

    a {
      @include type--secondary-link;
    }
  }

  .-signout-buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 4rem;

    button {
      min-width: auto;
      width: 100%;
      max-width: 350px;

      @include larger-than-tablet {
        max-width: 300px;
      }
    }

    button:first-of-type {
      margin-bottom: 8px;
    }
  }
}

.connect-paypal-settings {
  h1 {
    @include type--title;
  }
  .--button {
    margin: 0.5rem;
  }
  margin-bottom: 40px;
  text-align: center;
}

.connect-paypal-settings {
  h1 {
    @include type--title;
  }

  h3 {
    @include type--subtitle;
    margin-bottom: 3rem;
  }

  .details-subheader {
    @include type--body;
    margin-bottom: 3rem;
  }

  .loading {
    margin: 3rem;
  }

  .user-notifications {
    margin-bottom: 2rem;
  }
}
