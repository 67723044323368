@import "mixins/media-queries";
@import "storefront/components/styles/pxToRem.module.scss";
@import "stylesheets/shared/z-index";

.root {
  -webkit-font-smoothing: auto;
  align-items: center;
  background: var(--colors--primary-text);
  bottom: 0;
  color: var(--colors--white-background);
  display: flex;
  height: 55px;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  transition-property: bottom, height;
  transition-duration: 0.3s, 0s;
  z-index: $z-general-overlay;
  visibility: initial;
  overflow: hidden;

  &.hidden {
    height: 0px;
    bottom: -55px;
    transition-delay: 0s, 0.3s;
  }
}

.badge {
  display: block;
  width: 22px;

  path {
    fill: var(--colors--white-background);
  }
}

.message {
  padding: 0 25px;
}

.close {
  background-color: inherit;
  border: 0 none;
  position: absolute;
  right: 15px;
  width: 14px;
  line-height: 0;

  svg {
    stroke: var(--colors--white-background);
  }
}

.message,
.link {
  font-weight: bold;
}
