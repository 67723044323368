@mixin accordion {
  width: 135px;

  .--accordion {
    border-bottom: 1px solid var(--colors--light-stroke);
    color: var(--colors--primary-text);
    cursor: pointer;
    margin-bottom: 1px;
    overflow: hidden;
    padding-bottom: 15px;
    position: relative;
    width: 100%;

    .--accordion-input {
      opacity: 0;
      position: absolute;
      z-index: -1;

      &:checked ~ .--accordion-content {
        max-height: 100vh;
      }

      &:checked ~ svg {
        transform: rotate(-180deg);
      }
    }

    .--accordion-label {
      cursor: pointer;
      display: inline-block;
      margin-bottom: 0;
      position: relative;
    }

    .--accordion-caret {
      float: right;
      margin-top: 10px;
      transition: .2s transform ease;
    }

    .--accordion-content {
      max-height: 0;
      overflow: hidden;
      padding-left: 15px;
      transition: max-height .35s;
    }
  }
}
