@import "../../mixins/media-queries/index";
@import "../../mixins/type/index";

.Editorial--SiteBanner {
  background-color: black;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  justify-items: center;

  .-cta {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $colors--white-background;
    padding: 1em 1em;
    grid-column-start: 2;
  }

  .-logo {
    height: 25px;
    padding-right: 1em;
  }

  .-title {
    padding-right: 1em;
    @include type--body;
    font-size: 14px;
    flex-shrink: 0;
    display: none;

    @include larger-than-phone {
      display: inline;
    }
  }

  .-label {
    @include type--primary-link;
    flex-shrink: 0;
  }

  .-dismiss {
    color: $colors--white-background;
    cursor: pointer;
    padding-right: 1rem;
    margin-left: auto;
    visibility: hidden;

    &.enabled {
      visibility: visible;
    }
  }

  &.dismissed {
    display: none;
  }
}
