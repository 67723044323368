@import '../../../mixins/type/index';
@import '../../../mixins/button/index';

.Ad--Landing {
  .--Image-And-Copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5%;
    margin-bottom: 100px;
    
    @include larger-than-tablet {
      flex-direction: row;
      justify-content: space-around;
      padding: 0 15%;
    }
    
    .--image {
      width: 100%;
      margin-bottom: 30px;
      
      @include larger-than-tablet {
        width: 50%;
      }
    }
    
    .--content {
      text-align: center;
      
      @include larger-than-tablet {
        text-align: left;
        width: 40%;
      }
      
      .--title {
        @include type--display-2;
        
        @include larger-than-tablet {
          @include type--display-3;
        }
      }
      
      .--description {
        @include type--body;
        margin-bottom: 40px;
        
        body._grailed & {
          @include type--font-size-and-line-height(16px);
          font-weight: $type--weights--light;
        }
      }
      
      .--button {
        @include button;
        padding: 15px 25px;
        
        body._grailed & {
          @include type--font-size-and-line-height(14px);
        }
      }
    }
  }
}
