$max-phone-width: 480px;
$max-tablet-width: 770px;
$max-laptop-width: 1024px;

@mixin larger-than($size) {
  @media only screen and (min-width: #{$size + 1}) {
    @content;
  }
}

@mixin larger-than-phone {
  @include larger-than($max-phone-width) {
    @content;
  }
}

@mixin larger-than-tablet {
  @include larger-than($max-tablet-width) {
    @content;
  }
}

@mixin larger-than-laptop {
  @include larger-than($max-laptop-width) {
    @content;
  }
}
