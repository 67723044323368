.linkButton {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  appearance: none;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  font-family: inherit;
  font: inherit;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}
