.content {
  padding-top: 5.2rem;
  padding-bottom: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trigger {
  display: flex;
  margin-bottom: 0 !important;
}

.item {
  padding: 1.6rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  border-bottom: 0.5px solid var(--colors--light-stroke);
}

.caret {
  margin-left: auto;
  height: 1.6rem;
  width: 1.6rem;
}

.subItem {
  padding: 0.4rem 1.6rem;
  display: flex;
  align-items: center;
  height: 3rem;
  text-decoration: none;
  color: var(--colors--tertiary-text);
}
