@import '../../../mixins/button/index';
@import '../../../mixins/type/index';

.Editorial--HighSnobAd {
  box-sizing: border-box;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
  background-color: $colors--primary-text;
  padding: 4% 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 2px;

  @include larger-than-laptop {
    flex-direction: row;
    justify-content: space-between;
    height: 80px;
    margin-bottom: 100px;
    padding: 0 4%;
  }

  .-copy-and-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;

    .-copy {
      @include type--title;
      line-height: 16px;
      color: $colors--white-background;
      margin-bottom: 10px;
      margin-right: 15px;
      @include larger-than-laptop {
        margin-bottom: 0;
      }
    }

    .-logo {
      height: 30px;
      @include larger-than-laptop {
        height: 40px;
      }
    }

    @include larger-than-laptop {
      margin-bottom: 0;
      flex-direction: row;
    }
  }

  .-links {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @include larger-than-laptop {
      width: 40%;
      justify-content: flex-end;
    }

    .-link + .-link {
      margin-left: 15px;
    }

    .-link {
      @include button;
      white-space: nowrap;

      &._large {
        max-width: 200px;
        padding: 2%;
      }

      @include larger-than-laptop {
        margin-left: 15px;
      }
    }
  }
}
