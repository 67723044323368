@import '../../../mixins/type/index';
@import './mixins';

.Editorial--GiveawayHeroModule {
  position: relative;
  height: fit-content;

  .--hero {
    height: 500px;
    background-position: center;
    background-size: cover;
    margin-bottom: 90px;
  }

  .--content {
    @include heatwave--countdown;

    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;

    @include larger-than-tablet {
      padding: 0;
    }

    .--title {
      @include heatwave--type-large;
      padding: 0 1%;
      color: $colors--white-background;
      margin-bottom: 50px;

      @include larger-than-phone {
        @include heatwave--type-x-large;
        color: $colors--white-background;
      }
    }

    .--ended {
      @include type--font-size-and-line-height(30px);
      font-weight: $type--weights--bold;
      color: $colors--white-background;

      @include larger-than-phone {
        @include type--font-size-and-line-height(45px);
      }
    }

    .-brand {
      width: 300px;
      margin-bottom: 15px;
    }

    .--button {
      @include heatwave-button;
      padding: 22px 17px;
      width: auto;
    }
  }

  .--drop-text {
    @include type--headline-2;
    text-align: center;
    margin-top: -40px;
    margin-bottom: 40px;
  }

  .--step-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -140px;
    margin-bottom: -100px;

    @include larger-than-tablet {
      flex-direction: row;
      margin: 0 auto;
      margin-bottom: -100px;
      width: 100%;
      max-width: 1300px;
      justify-content: center;
    }

    .--step-item {
      box-sizing: border-box;
      text-align: center;
      width: 80%;
      height: 220px;
      padding: 0 10%;
      background: $colors--white-background;
      border: 1px solid $colors--light-stroke;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include larger-than-tablet {
        padding: 20px 10px 0 10px;
        margin: 0px 10px 20px 10px;
        width: 23%;
        justify-content: flex-start;
      }

      .--icon--circle {
        border: 1px solid $colors--secondary-text;
        border-radius: 100%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;

        .--icon {
          height: 25px;
          width: 25px;
        }
      }

      .--step-title {
        @include type--primary-link;
      }

      .--description p {
        margin-bottom: 0;
      }

      .--signed-up {
        margin-bottom: 0;
        font-weight: $type--weights--bold;
      }

      a {
        @include type--secondary-link;
      }
    }
  }


  &._Heatwave {
    .--content {
      .--brand {
        height: 20px;
      }
    }
  }

  &._SocialStudies {
    .--hero {
      margin-bottom: 40px;

      @include larger-than-phone {
        margin-bottom: 45px;
        height: 500px;
      }
    }

    .--content {
      height: 450px;

      @include larger-than-phone {
        height: 500px;
      }

      .--countdown {
        margin-bottom: 30px;

        @include larger-than-phone {
          margin-bottom: 35px;
        }
      }

      .--title {
        margin-bottom: 30px;

        @include larger-than-phone {
          margin-bottom: 35px;
        }
      }

      .--button {
        box-shadow: none;

        &:hover, &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
