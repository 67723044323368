@use "sass:math";

.root {
  padding: 100px 25px;
  text-align: center;
}

.message {
  margin: 0;

  &:after {
    content: "...";
  }
}

.message + .icon {
  margin-top: 30px;
}

.icon {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;

  &:after {
    content: "";

    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    width: 32.5px;
    height: 34px;

    background-image: url(https://media-assets.grailed.com/prd/misc/RVGWQO51K1IHOA39OIQWRT7I2VDKMIC4ELY5SW);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 177.5px;

    transform: translate(-50%, -50%) scale(1);

    animation-name: cycle;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  $icons: (
    "shirt": (
      "index": 0,
      "width": 32.5px,
      "height": 34px,
      "position": 0,
      "next": "pants",
    ),
    "pants": (
      "index": 1,
      "width": 30px,
      "height": 34px,
      "position": -32.5px,
      "next": "shoe",
    ),
    "shoe": (
      "index": 2,
      "width": 25px,
      "height": 34px,
      "position": -63px,
      "next": "jacket",
    ),
    "jacket": (
      "index": 3,
      "width": 33px,
      "height": 34px,
      "position": -88px,
      "next": "cap",
    ),
    "cap": (
      "index": 4,
      "width": 30px,
      "height": 34px,
      "position": -121px,
      "next": "suit",
    ),
    "suit": (
      "index": 5,
      "width": 27px,
      "height": 34px,
      "position": -151px,
      "next": "shirt",
    ),
  );

  @keyframes cycle {
    @each $name, $currentProps in $icons {
      $index: map-get($currentProps, "index");
      $nextProps: map-get($icons, map-get($currentProps, "next"));
      $slice: (math.div(1, length($icons)) * 100%);
      $percent: $slice * $index;

      #{$percent} {
        width: map-get($currentProps, "width");
        height: map-get($currentProps, "height");
        background-position: map-get($currentProps, "position") 0;
        transform: translate(-50%, -50%) scale(0);
      }

      #{$percent + 0.001%} {
        width: map-get($nextProps, "width");
        height: map-get($nextProps, "height");
        background-position: map-get($nextProps, "position") 0;
        transform: translate(-50%, -50%) scale(0);
      }

      #{$percent+($slice * 0.5)} {
        transform: translate(-50%, -50%) scale(1);
      }
    }

    100% {
      transform: translate(-50%, -50%) scale(0);
    }
  }
}
