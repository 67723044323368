.pp-trouble-checking-out {
  width: 380px;
  padding: 40px 40px 80px;
  text-align: center;

  & > h1 {
    text-transform: uppercase;
    font-family: $control-font;
    font-weight: 600;
    color: $colors--gray-banner;
  }

  & > p {
    font-size: 11px;
    font-family: $sub-title-font;

    .pp-phone-number {
      font-weight: 600;
      color: $colors--info;
      text-decoration: none;
    }
  }

  .pp-logo {
    display: inline-block;
    vertical-align: middle;
    width: 46px;
    height: 11px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-image: url("https://media-assets.grailed.com/prd/misc/CRRO0C5HFMPFL1VDXIBA0-ACJSNF4SPQ-_QQCQ");
  }
}
