@import "../../mixins/tooltip/index";
@import "../../mixins/media-queries/index";

.--admin-user-flair,
.--paypal-seller-flair,
.--verified-user-flair,
.--grailed-verified-user-flair,
.--trusted-seller-flair,
.--quick-responder-flair,
.--speedy-shipper-flair {
  margin-bottom: 0;
  display: inline-block;

  .conversations-wrapper & {
    height: 10px;
    width: 10px;
  }
}

.--verified-user-flair {
  background-image: url("https://media-assets.grailed.com/prd/misc/BO0LC4RXJNCQC4XEXRO8KQYVOGTBW49OM2NNDQ");
}

.--paypal-seller-flair {
  background-image: url("https://media-assets.grailed.com/prd/misc/U1N-QAFLOGAC8CI-M9LDSAVGNQB3JOYJWMTPAQ");
}

.--tooltip,
.--flair-tooltip {
  @include tooltip;
}

.--flair-tooltip {
  margin-left: 5px;
  display: inline-block;

  @include larger-than-phone {
    margin-left: 8px;
  }
}
