@import '../../../mixins/type/index';
@import '../../../mixins/button/index';

.Ad--Landing {
  .--Brand-List {
    margin-bottom: 60px;
    padding: 0 10%;
    padding-bottom: 60px;
    border-bottom: 1px solid $colors--light-stroke;
    text-align: center;
    position: relative;
    
    @include larger-than-tablet {
      margin-bottom: 100px;
    }
    
    .--title {
      @include type--headline-2;
      text-align: center;
      margin-bottom: 35px;
    }
    
    .--brand-items-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      margin-bottom: 40px;
      
      @include larger-than-phone {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
      }
      
      @include larger-than-tablet {
        grid-template-columns: repeat(8, 1fr);
      }
      
      .--brand-item {
        line-height: 0;
        .--image {
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          border: 1px solid $colors--light-stroke;
        }
      }
    }
    
    .--button {
      @include button;
      
      @include larger-than-phone {
        margin: 0 auto;
      }
      
      @include larger-than-tablet {
        &._x-large {
          position: absolute;
          top: 5px;
          right: 10%;
          padding: 2px 6px;
          width: auto;
          height: auto;
          border: 1px solid $colors--light-stroke;
          
          body._grailed & {
            @include type--form-label;
          }
        }
      }
      
      .--arrow {
        visibility: hidden;
        
        @include larger-than-tablet {
          visibility: visible;
          margin-left: 5px;
        }
      }
    }
  }
}