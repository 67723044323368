@import "../../../mixins/type/index";
@import "../../../mixins/grid/index";

.Editorial--WeekPreviewsModule {
  display: flex;
  flex-direction: column;

  .--previous-drops {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2rem 0;
    border-top: 1px solid $colors--light-stroke;
    border-bottom: 1px solid $colors--light-stroke;
    background-color: $colors--off-white-background;

    .--previous-title {
      @include type--display-3;
    }

    .--previous-links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @include type--subtitle;

      a {
        margin: 1rem;
      }
    }
  }
}
