@import "mixins/media-queries/index";

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.6rem;

  @include larger-than-tablet {
    gap: 2.4rem;
  }
}

.action {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--colors--primary-text);
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  position: relative;
}

.notified {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 100%;
    background-color: var(--colors--alert);
  }
}

.sell {
  padding: 0.4rem 1.6rem;
}

.sell, .forYou {
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
  }
}

.border {
  border: 1px solid var(--colors--accent)
}

.actionButton {
  @media screen and (max-width: 770px) {
    padding: 0;
    color: var(--colors--primary-text);
    font-size: 1.2rem;
    font-weight: 700;
    border: none;
    background: none;
    font-family: var(--base-fonts);

    &:hover {
      background: none;
    }
  }
}
