@import "../media-queries/index";

$type--families--system: "SF Pro Text", -apple-system, Helvetica, Arial,
  "Segoe UI", Roboto, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";
$type--families--roboto: "Roboto", sans-serif;
$type--families--noe-display: "NoeDisplayRegular", serif;
$type--families--function-pro-book: "FunctionProBook", sans-serif;
$type--families--function-pro-light: "FunctionProLight", sans-serif;
$type--families--video: "Video", monospace;

$type--weights--ultra-light: 100;
$type--weights--thin: 200;
$type--weights--light: 300;
$type--weights--regular: 400;
$type--weights--medium: 500;
$type--weights--bold: 600;
$type--weights--heavy: 700;
$type--weights--black: 800;
$type--weights--extra-black: 900;

@mixin type--font-size-and-line-height($size) {
  font-size: $size;
  // Add 10, round to nearest multiple of 5.
  line-height: ((round((($size + 10px) * 2) * 0.1) * 10) * 0.5);
}

@mixin type--base {
  font-family: $type--families--system;
  font-weight: $type--weights--regular;
  font-style: normal;
  @include type--font-size-and-line-height(16px);
  letter-spacing: normal;
  text-align: inherit;
  text-transform: none;
  color: inherit;
  text-decoration: none;
}

// General Type Settings

@mixin type--marketplace-header-font {
  body._grailed & {
    font-family: $type--families--system;
  }
}

@mixin type--display-editorial-1 {
  @include type--base;
  font-size: 35px;
  line-height: 35px;

  body._grailed & {
    font-family: $type--families--system;
    font-weight: $type--weights--black;
  }

  @include larger-than-phone {
    font-size: 50px;
    line-height: 50px;
  }
  @include larger-than-tablet {
    font-size: 80px;
    line-height: 80px;
  }
}

@mixin type--display-editorial-2 {
  @include type--base;
  font-size: 35px;
  line-height: 35px;

  body._grailed & {
    font-family: $type--families--system;
    font-weight: $type--weights--light;
  }

  @include larger-than-phone {
    font-size: 50px;
    line-height: 50px;
  }
  @include larger-than-tablet {
    font-size: 80px;
    line-height: 80px;
  }
}

@mixin type--display-1 {
  @include type--base;
  @include type--font-size-and-line-height(35px);
  @include larger-than-phone {
    @include type--font-size-and-line-height(45px);
  }
  @include larger-than-laptop {
    @include type--font-size-and-line-height(55px);
  }

  @include type--marketplace-header-font;
}

@mixin type--display-2 {
  @include type--base;
  @include type--font-size-and-line-height(25px);
  font-weight: $type--weights--medium;
  @include larger-than-phone {
    @include type--font-size-and-line-height(35px);
  }
  @include larger-than-laptop {
    @include type--font-size-and-line-height(45px);
  }
}

@mixin type--display-3 {
  @include type--base;
  @include type--font-size-and-line-height(25px);
  @include larger-than-phone {
    @include type--font-size-and-line-height(35px);
  }
  @include type--marketplace-header-font;
}

@mixin type--display-4 {
  @include type--base;
  @include type--font-size-and-line-height(25px);
  @include type--marketplace-header-font;
}

@mixin type--headline-1 {
  @include type--base;
  @include type--font-size-and-line-height(20px);
  font-weight: $type--weights--bold;

  @include larger-than-phone {
    @include type--font-size-and-line-height(30px);
  }
}

@mixin type--headline-2 {
  @include type--base;
  @include type--font-size-and-line-height(20px);
  font-weight: $type--weights--bold;
}

@mixin type--headline-feed {
  @include type--base;
  font-weight: $type--weights--bold;
  font-size: 20px;
  line-height: 120%;

  @include larger-than-phone {
    font-size: 32px;
    line-height: 120%;
  }
}

@mixin type--title {
  @include type--base;
  font-weight: $type--weights--bold;
}

@mixin type--subtitle {
  @include type--base;
  @include type--font-size-and-line-height(14px);
  font-weight: $type--weights--heavy;
}

@mixin type--subtitle-2 {
  @include type--base;
  @include type--font-size-and-line-height(12px);
  font-weight: $type--weights--heavy;
}

@mixin type--body-editorial {
  @include type--base;
  @include type--font-size-and-line-height(20px);
  font-weight: $type--weights--light;
}

@mixin type--body {
  @include type--base;
  @include type--font-size-and-line-height(14px);
}

@mixin type--body-conversation {
  @include type--base;
}

@mixin type--body-caption {
  @include type--base;
  @include type--font-size-and-line-height(12px);
}

@mixin type--form-label {
  @include type--base;
  @include type--font-size-and-line-height(12px);
  font-weight: $type--weights--bold;
}

@mixin type--editorial-link {
  @include type--base;
  @include type--font-size-and-line-height(20px);
  font-weight: $type--weights--light;
}

@mixin type--large-link {
  @include type--base;
  @include type--font-size-and-line-height(14px);
  text-transform: uppercase;

  body._grailed & {
    font-family: $type--families--system;
    font-weight: $type--weights--bold;
  }
}

@mixin type--primary-link {
  @include type--base;
  @include type--font-size-and-line-height(12px);
  text-transform: uppercase;

  body._grailed & {
    font-family: $type--families--system;
    font-weight: $type--weights--bold;
  }
}

@mixin type--secondary-link {
  @include type--base;
  @include type--font-size-and-line-height(12px);
  text-decoration: underline;
}

@mixin type--super-link {
  @include type--base;
  @include type--font-size-and-line-height(14px);
  font-family: $type--families--video;
  text-transform: uppercase;
  color: var(--colors--blue-highlight);

  &::after {
    content: " →";
  }
}

@mixin type--small-super-link {
  @include type--super-link;
  @include type--font-size-and-line-height(12px);
}

@mixin type--toggle {
  @include type--base;
  @include type--font-size-and-line-height(12px);
  text-transform: uppercase;

  body._grailed & {
    font-family: $type--families--system;
    font-weight: $type--weights--bold;
  }
}

// Listing Details Type Settings

@mixin type--designer-name-large {
  @include type--base;

  body._grailed & {
    font-family: $type--families--system;
    font-weight: $type--weights--bold;
    text-transform: uppercase;
  }
}
@mixin type--designer-name-card {
  @include type--base;

  body._grailed & {
    font-family: $type--families--system;
    font-weight: $type--weights--bold;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    @include type--font-size-and-line-height(12px);
  }
}

@mixin type--item-name-large {
  @include type--base;

  body._grailed & {
    @include type--font-size-and-line-height(14px);
  }
}
@mixin type--item-name-card {
  @include type--base;

  body._grailed & {
    @include type--font-size-and-line-height(12px);
  }
}

@mixin type--size-large {
  @include type--base;

  body._grailed & {
    @include type--font-size-and-line-height(14px);
    text-transform: uppercase;
  }
}
@mixin type--size-card {
  @include type--base;

  body._grailed & {
    font-family: $type--families--system;
    font-weight: $type--weights--bold;
    @include type--font-size-and-line-height(12px);
    text-transform: uppercase;
    letter-spacing: 0.4px;
  }
}

@mixin type--timestamp {
  @include type--base;
  @include type--font-size-and-line-height(12px);
}

@mixin type--timestamp-card {
  @include type--base;
  @include type--font-size-and-line-height(10px);
}

@mixin type--price-large {
  @include type--base;
  letter-spacing: 0.075em;

  body._grailed & {
    @include type--font-size-and-line-height(16px);
    font-weight: $type--weights--bold;
  }

  &._dropped {
    text-decoration: line-through;
    @include type--font-size-and-line-height(12px);
  }
}
@mixin type--price-card {
  @include type--base;
  @include type--font-size-and-line-height(12px);

  body._grailed & {
    font-family: $type--families--system;
    font-weight: $type--weights--bold;
    letter-spacing: 0.4px;
  }

  &._dropped {
    text-decoration: line-through;
  }
}

@mixin type--username-large {
  @include type--base;

  body._grailed & {
    font-family: $type--families--system;
  }
}
@mixin type--username-card {
  @include type--base;

  body._grailed & {
    font-family: $type--families--system;
    @include type--font-size-and-line-height(16px);
  }
}

@mixin type--pagination {
  @include type--base;

  body._grailed & {
    font-family: $type--families--system;
    @include type--font-size-and-line-height(16px);
  }
}

// Button Type Settings

@mixin type--button {
  @include type--base;
  @include type--font-size-and-line-height(12px);
  text-transform: uppercase;

  body._grailed & {
    font-family: $type--families--video;
    font-weight: $type--weights--medium;
  }
}
