@import "mixins/media-queries/index";

.phoneInputs {
  .countryCode {
    left: 1em;
    position: absolute;
    top: 44px;
    font-size: 16px;

    &.listingPage {
      top: 46px;

      @include larger-than-tablet {
        top: 47px;
      }
    }
  }

  .countrySelectWrapper {
    margin-bottom: 24px;
  }

  .countrySelectControl {
    padding: 15px;
    height: auto;
  }

  .error {
    color: var(--colors--alert);
    margin-top: -20px;
    margin-bottom: 24px;
  }

  .intro {
    line-height: 1.5;
    text-align: left;
    font-size: 14px;
  }

  .label {
    text-align: left;
  }

  .phoneNumber {
    margin-bottom: 24px;
    padding: 15px;
    height: auto;

    &.charPadding1 {
      padding-left: 40px;
    }

    &.charPadding2 {
      padding-left: 50px;
    }

    &.charPadding3 {
      padding-left: 60px;
    }
  }

  .phoneNumberWrapper {
    position: relative;
  }

  .sendCode {
    margin-bottom: 16px;
  }

  .sendCodeLink {
    height: 1.5em;
    margin-top: 8px;
    margin-bottom: 8px;

    &[disabled], &[disabled]:hover {
      background: transparent;
      border-color: transparent;
      color: var(--colors--secondary-text);
    }
  }

  .form {
    margin-bottom: 2rem;
  }
}
