@import "../../../mixins/onboarding-tooltip.scss";

.conversation-links-wrapper {
  $convo-links-button-background-color: $colors--white-background;
  $convo-links-button-text-color: $colors--primary-text;
  $convo-links-border-color: $colors--primary-text;

  .conversation-links {
    display: flex;
    gap: 10px;

    button, a {
      width: 100%;
    }

    // HACK: Counter button is now wrapped in a div. [Shirley 2018-09-13]
    .ConversationLinks--Counter,
    .ConversationLinks--Offer {
      position: relative;
      flex: 1;

      .-tooltip {
        @include onboarding-tooltip;
        width: 260px;
        right: 50%;
        top: -50%;
        transform: translate(-85%, -75%);

        &::before {
          right: 35px;
          border-bottom-color: transparent;
          border-top-color: $colors--blue-highlight;
          top: 40px;
        }
      }

      .--button {
        width: 100%;
      }
    }

    .--button {
      flex: 1;
      display: block;
      padding: 22px 0;
      min-width: 0;
      height: auto;
      border: none;
      border-radius: 0;
      margin: 0;
      background-color: $convo-links-button-background-color;
      font-weight: 600;
      font-size: 12px;
      font-weight: 300;
      line-height: 14px;
      letter-spacing: normal;
      text-decoration: none;
      color: $convo-links-button-text-color;
      text-transform: uppercase;
      text-align: center;

      // HACK: Overrides an inherited style. [Evan 2017-10-12]
      &:hover {
        box-shadow: none;
      }

      &._disabled {
        opacity: 0.3;
      }
    }

    // hack while combining button components and button mixin on this page
    // while migrating fully to button components
    .--button-component {
      border-top: 1px solid #000000;
      border-bottom: 1px solid #000000;
      border-left: none;
      font-weight: 600;
      flex: 1;
      font-size: 12px;
      line-height: 14px;
      background-color: $convo-links-button-background-color;
      color: $convo-links-button-text-color;
    }

    .--button-component + .--button,
    .--button + .--button {
      border-left: 1px solid $convo-links-border-color;
    }
  }
}
