@import "../../../../../mixins/media-queries/index";

.Editorial--DropProductsModule--Active--ProductsWithImageLayout {
  position: relative;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(2, calc(50% - 0.165rem));
  grid-column-gap: 0.33rem;
  grid-row-gap: 1rem;
  justify-content: space-between;
  margin: 0 0rem 4rem 0rem;

  @include larger-than-phone {
    max-width: 1034px; // grabbed from the main feed width
    margin: 0 auto 4rem auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .carousel-listing-item {
    width: 100%;
  }

  .--image {
    max-width: 100%;
    max-height: 100%;
    align-self: center;

    // on mobile stick any "top" placement at the top
    &._top-left, &._top-right {
      grid-column: 1 / span 2;
      grid-row: 1 / span 2;
    }

    // on mobile stick any "bottom" placement at the bottom
    &._bottom-left, &._bottom-right {
      grid-column: 1 / span 2;
      grid-row: 6 / span 2;
    }

    @include larger-than-phone {
      &._top-left {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
        max-width: 125%;
        margin-left: -25%;
      }

      &._top-right {
        grid-column: 3 / span 2;
        grid-row: 1 / span 2;
        max-width: 125%;
      }

      &._bottom-left {
        grid-column: 1 / span 2;
        grid-row: 2 / span 2;
        max-width: 125%;
        margin-left: -25%;
      }

      &._bottom-right {
        grid-column: 3 / span 2;
        grid-row: 2 / span 2;
        max-width: 125%;
      }
    }
  }

  .Editorial--DropProductsModule--Active--SoldOut {
    grid-column: 1 / span 2;
    grid-row: 5 / span 1;
    text-align: center;
    width: calc(100% - 2rem);
    margin: auto;
    margin-bottom: 3rem;

    @include larger-than-phone {
      grid-column: 1 / span 4;
      grid-row: 4 / span 1;
      max-height: fit-content;
    }
  }
}
