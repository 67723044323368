@import "storefront/components/styles/pxToRem.module.scss";

.codeInputs {
  .codeField {
    box-sizing: border-box;
    display: inline-block;
    margin: 0 pxToRem(5);
    max-width: pxToRem(60);
    text-align: center;
    width: pxToRem(43);

    .codeFieldInput {
      text-align: center;
      height: auto;
    }
  }

  .codeFieldsWrapper {
    margin: 0 auto 2rem auto;
    max-width: 215px;
  }

  .error {
    color: var(--colors--alert);
    margin-top: 5px;
    margin-bottom: 24px;
  }

  .intro {
    line-height: 1.5;
    text-align: left;
  }

  .resendCode {
    height: 1.5em;
    margin-top: 8px;
    margin-bottom: 8px;

    &[disabled],
    &[disabled]:hover {
      background: transparent;
      border-color: transparent;
      color: var(--colors--secondary-text);
    }
  }

  .submitCode {
    margin-top: 16px;
  }

  .form {
    margin-bottom: 2rem;
  }
}
