// NOTE: The second one in each selector is the current one. Those are all
// deprecated and should go away. [Evan 2017-11-03]
.menu, .account-sections {
  $item-color: #9B9B9B;
  $selected-item-color: #1A1A1A;
  $animation-duration: 300ms;

  .--item, a {
    display: block;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: normal;
    line-height: 1.666666667em;
    text-transform: uppercase;
    color: $item-color;
    transition: color $animation-duration ease;

    &:hover {
      .--item-text:after,
      h3:after {
        width: 100%;
      }
    }

    &.__selected,
    &.selected {
      color: $selected-item-color;

      .--item-text:after,
      h3:after {
        width: 100%;
        background-color: $selected-item-color;
      }
    }

    .--item-text,
    // HACK: h3 has a bunch of bagage we need to deal with. [Evan 2017-11-09]
    h3 {
      position: relative;
      display: inline-block;
      padding-bottom: 2px;
      margin: 0;
      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      text-transform: inherit;
      color: inherit;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 0;
        height: 1px;
        background-color: $item-color;
        transition: width $animation-duration ease;
      }
    }
  }
  .--item + .--item { margin-top: 20px; }
  a + a { margin-top: 20px; }
}
