@import "../mixins/type/index";
@import "../mixins/button/index";
@import "../mixins/media-queries/index";

#paypal_connect {
}

.pay-pal-connect-wrapper {
  .container {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    max-width: 720px;
  }

  p {
    color: $colors--gray-banner;
    font-size: 16px;
    line-height: 1.75;
    text-align: left;
    letter-spacing: 0.2px;
    font-family: $sub-title-font;
    max-width: 720px;

    margin-left: auto;
    margin-right: auto;

    &:nth-child(3) {
      margin-bottom: 50px;
    }

    @include phone-and-tablet {
      padding: 0 10px;
    }
  }

  h2 {
    @include type--headline-2;
    margin-bottom: 50px;
    text-align: center;
  }

  .-button {
    @include button;
    min-width: 300px;
    margin: 0 auto 89px;
  }

  .view-context-buttons.desktop {
    display: flex;
    @include phone-and-tablet {
      display: none;
    }
  }
  .view-context-buttons.mobile {
    display: none;
    @include phone-and-tablet {
      display: flex;
    }
  }

  .view-context-buttons {
    font-family: $title-font;
    text-transform: none;
    border: none;
    margin-bottom: 60px;

    .context-button {
      font-size: 20px;

      &.active {
        font-weight: 300;
        box-shadow: inset -1px 0 0 0 #e6e6e6, inset 1px 0 0 0 #e6e6e6,
          inset 0 1px 0 0 #e6e6e6, inset 0 -2px 0 0 #000000;
        border-bottom: 1px solid $colors--primary-text;
        border-width: 0px 0px 0px 1px;
      }
    }
  }

  .logos {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 7%;

    .marketplace-logo {
      background-image: url("https://media-assets.grailed.com/prd/misc/ENUWEDJ-VVRCPSWYS29-DFDZ4LPVTSTKDHDB0G");
      background-size: 145px 17px;
      width: 145px;
      height: 17px;
      margin-right: 8px;
    }
    .--icon {
      display: block;
    }
  }
  .hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 280px;
    margin-bottom: 0;

    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)),
      url(https://media-assets.grailed.com/prd/misc/ORJTRG01BCZ24XO9TIT_FEPVEI5JUCYIL0E4WW);
    background-size: cover;
    background-repeat: no-repeat;

    h1 {
      font-size: 54px;
      font-family: $title-font;
      text-transform: none;
      font-weight: 800;
      color: $colors--white-background;
    }

    @include phone {
      min-height: 120px;
      h1 {
        font-size: 24px;
        margin-bottom: 0;
      }
    }
  }
}
