@import "../styles/pxToRem.module.scss";

.badge {
  border-style: solid;
  border-width: pxToRem(1);
  cursor: default;
  display: inline-block;
  font-family: var(--base-fonts);
  font-size: pxToRem(12);
  font-weight: 500;
  line-height: pxToRem(20);
  letter-spacing: 0.03em;
  padding: pxToRem(0) pxToRem(8);
  text-align: center;
  white-space: nowrap;
  font-family: "Video", monospace;
  text-transform: uppercase;
}

.primary {
  background-color: var(--colors--primary-text);
  border-color: var(--colors--primary-text);
  color: var(--colors--white-background);
}

.secondary {
  background-color: var(--colors--white-background);
  border-color: var(--colors--primary-text);
  color: var(--colors--primary-text);
}

.tertiary {
  background-color: var(--colors--off-white-background);
  border-color: var(--colors--off-white-background);
  color: var(--colors--secondary-text);
}

.inactive {
  background-color: var(--colors--white-background);
  border-color: var(--colors--light-stroke);
  color: var(--colors--primary-text);
}

.alert {
  background-color: var(--colors--alert-background);
  border-color: var(--colors--alert);
  color: var(--colors--alert);
}

.success {
  background-color: var(--colors--success-tint-green);
  border-color: var(--colors--success);
  color: var(--colors--primary-text);
}

.notify {
  background-color: var(--colors--bad-tint);
  border-color: var(--colors--alert);
  color: var(--colors--primary-text);
}

.warning {
  background-color: var(--colors--warning-tint);
  border-color: var(--colors--warning);
  color: var(--colors--primary-text);
}
