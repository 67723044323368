@import "mixins/type/_index.scss";

%textDefaults {
  font-family: var(--base-fonts);
  color: inherit;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
}

%textReset {
  margin: 0;
}

%textVars {
  --font-weight--light: 300;
  --font-weight--regular: 400;
  --font-weight--semibold: 600;
  --font-weight--bold: 700;
  --font-weight--heavy: 800;
}

.Text {
  @extend %textVars;
  @extend %textReset;
  @extend %textDefaults;
}
