@import "./media-queries/index";

.view-context-buttons {
  $border-color: var(--colors--light-stroke);
  $button-text-color: var(--colors--gray-banner);
  $button-background-color: var(--colors--off-white-background);
  $active-button-background-color: var(--colors--white-background);
  $active-button-text-color: var(--colors--primary-text);
  $active-shadow-color: var(--colors--primary-text);

  $active-transition-duration: 250ms;

  display: flex;
  align-items: stretch;
  border: 0;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  border-radius: 0;
  margin: 0;

  @include larger-than-phone {
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
  }

  .context-button {
    position: relative;
    flex: 1;
    padding: 21px 0 20px;
    border: 0;
    background-color: $button-background-color;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    color: $button-text-color;
    cursor: pointer;
    transition-duration: $active-transition-duration;
    transition-property: font-weight, background-color, color;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 0;
      background-color: $active-shadow-color;
      transition: $active-transition-duration height;
    }

    @include larger-than-phone {
      padding: 16px 0;
    }

    &.active {
      background-color: $active-button-background-color;
      color: $active-button-text-color;

      &:after {
        height: 4px;

        @include larger-than-phone {
          height: 0;
        }
      }
    }

    .drafts-dot {
      height: 18px;
      line-height: 1;
      .dot {
        color: var(--colors--alert);
        padding: 2px;
      }
    }
  }

  // HACK: This is a cheap trick to get around the fact that the separator
  // element is still there; once it is removed from the HTML, this selector can
  // be simplified to .context-button + .context-button {}. [Evan 2017-10-06]
  .context-button + .separator + .context-button {
    border-left: 1px solid $border-color;
  }

  .separator {
    display: none;
  }
}
