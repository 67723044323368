@import "../../../../../storefront/components/styles/pxToRem.module.scss";
@import "../../mixins/type/index";

$video-font: $type--families--video;
$control-font: $type--families--system;
$control-font-semibold: $type--families--system;

$title-font: $type--families--system;
$title-font-medium: $type--families--system;
$title-font-bold: $type--families--system;

$sub-title-font: $type--families--system;
$sub-title-font-bold: $type--families--system;

body._grailed {
  // Fonts
  --video-font: #{$video-font};
  --control-font: #{$control-font};
  --control-font-semibold: #{$control-font-semibold};

  --title-font: #{$title-font};
  --title-font-medium: #{$title-font-medium};
  --title-font-bold: #{$title-font-bold};

  --sub-title-font: #{$sub-title-font};
  --sub-title-font-bold: #{$sub-title-font-bold};

  --base-fonts: #{$type--families--system};

  // box shadow
  --standard-elevation: 0px 2px 6px rgba(0, 0, 0, 0.2);

  // border-radius
  --shape-radius-input: #{pxToRem(4)};
  --shape-radius-card: #{pxToRem(8)};

  // spacing
  --spacing-6: #{pxToRem(6)};
  --spacing-8: #{pxToRem(8)};
  --spacing-12: #{pxToRem(12)};
  --spacing-16: #{pxToRem(16)};
  --spacing-24: #{pxToRem(24)};
  --spacing-32: #{pxToRem(32)};

  // layout
  --layout-40: #{pxToRem(40)};
  --layout-50: #{pxToRem(50)};
  --layout-60: #{pxToRem(60)};
  --layout-100: #{pxToRem(100)};
  --layout-150: #{pxToRem(150)};
}
