.phoneVerification {
  .verificationInfo {
    font-size: 12px;
    line-height: 1.5;
    text-align: left;
    display: flex;
    flex-direction: row;

    svg {
      margin: 3px 16px 0 0;
      vertical-align: middle;
      flex-shrink: 0;
      flex-grow: 0;
    }

    .verificationDisclaimer {
      flex-grow: 1;
    }
  }
}
