
// Adds a placeholder for divs or images that are loading

@mixin loading($image-width, $image-height) {
  // Color must be hardcoded to have access to darken function
  $tint-color: #fafafa;
  $base-color: darken($tint-color, 2%);

  background-color: $base-color;
  overflow: hidden;
  width: $image-width;
  height: $image-height;
  position: relative;

  &::after {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-$image-width);
    width: $image-width * 2;
    height: $image-height;

    background: linear-gradient(
      to right,
      $base-color,
      $tint-color $image-width,
      $base-color $image-width,
      $base-color
    );
  }

  &._loading::after {
    display: block;
    animation-name: loading;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  @keyframes loading {
    0% {
      transform: translate(-$image-width);
    }
    50% {
      transform: translate($image-width);
    }
    100% {
      transform: translate($image-width);
    }
  }
}
