.listingPriceAndHeart {
  box-sizing: border-box;
  display: flex;
  padding: 0 0 3px 0;
  height: 23px;

  .listingPrice {
    display: flex;
    flex: 1;
    align-items: center;
    padding-left: 8px;
    padding-top: 2px;
  }

  .saveListing,
  .favoriteListing {
    width: 24px;
    height: 24px;
  }

  .saveListing svg {
    width: 14px;
    height: 12px;
  }

  .favoriteListing svg {
    width: 16px;
    height: 13px;
  }
}
