@import "../../../mixins/onboarding-tooltip.scss";

.conversation-links-wrapper {
  .conversation-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .--button {
      @include button;
      margin-bottom: 0;
    }

    // hack while combining button components and button mixin on this page
    // while migrating fully to button components
    .--button-component {
      font-size: 12px;
      line-height: 20px;
      padding: 4px 11px;
      min-width: unset;
    }

    .--button-component + .--button-component,
    .--button-component + .--button {
      margin-left: 10px;
    }

    .ConversationLinks--Counter {
      margin: 0 10px;
      position: relative;

      ._disabled {
        cursor: initial;
      }

      .-tooltip {
        @include onboarding-tooltip;
        width: 260px;
        left: 0%;
        top: 100%;
        transform: translate(-72%, 0%);

        &::before {
          right: 35px;
        }
      }
    }

    .--button + .--button {
      margin-left: 10px;
    }

    // sometimes there is a <noscript /> in between
    .seller-tracking ~ .--button {
      margin-left: 10px;
    }

    .desktop-grailed-label {
      background-color: $colors--primary-text;
      border-color: $colors--primary-text;
      color: $colors--white-background;

      &:hover {
        background-color: var(--colors--gray-banner);
        border-color: var(--colors--gray-banner);
      }
    }

    .tracking-info {
      display: inline-block;

      * {
        margin-bottom: 0;
      }

      .input {
        width: 130px;
        height: 30px;
        font-size: 12px;
        margin-right: 10px;
        &._error {
          &::-webkit-input-placeholder {
            color: $colors--alert;
          }
        }
      }
    }

    .tracking-items {
      list-style: none;
      font-size: 14px;

      .tracking-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      a {
        text-decoration: underline;
        font-weight: 500;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #4a4a4a;
        margin-right: 10px;
      }
    }

    .form {
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-align-items: flex-start;
      align-items: center;
      display: inline-flex;
    }

    select {
      padding: 0px 6px;
    }
  }
}
