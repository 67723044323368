@import "../../mixins/media-queries/index";
@import "../../mixins/button/index";

body.conversations {
  padding-bottom: 0;
}

#conversations {
  $larger-than-phone-stroke-color: $colors--medium-stroke;
  padding: 0;

  & > #loading {
    padding-top: 60px;
  }

  .conversations-wrapper {
    padding: 0;

    @include larger-than($phone-width) {
      padding-bottom: 90px;
    }
    .conversations-error button {
      @include button;
    }

    .archived-messages {
      text-align: center;
      margin-top: 16px;

      a {
        text-decoration: underline;
      }
    }
  }

  // NOTE: These are nested within #conversations because they might overwrite
  // selectors used elsewhere throughout the site. [Evan 2017-10-06]
  @import "./horizontal-choice";
  @import "../../mixins/view-context-buttons";
  @import "./empty-conversation-disclaimer";
  @import "./conversation-item/index";

  .horizontal-choice + .view-context-buttons {
    margin-top: 30px;
  }

  .view-context-buttons + .empty-conversation-disclaimer {
    margin-top: 30px;
  }

  .view-context-buttons + .conversation-item {
    margin-top: 0;

    @include larger-than($phone-width) {
      margin-top: 30px;
      border-top: 1px solid $larger-than-phone-stroke-color;
    }
  }
}
