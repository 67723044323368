@import "../../../mixins/badge/index";
@import "../../../mixins/disclaimer/index";

.conversation-item-activity-log {
  $activity-item-border-color: $colors--light-stroke;
  $them-activity-item-background-color: $colors--light-stroke;
  $me-activity-item-background-color: $colors--white-background;
  $activity-item-text-color: $colors--gray-banner;
  $purchased-border-color: $colors--seafoam-green;

  flex: 1;
  padding: 12px;
  overflow-y: scroll;

  // NOTE: This is here to make scrolling on mobile more smooth. Perhaps it's a
  // good candidate for a mixin? [Evan 2017-10-12]
  transition-property: height, width;
  transition-duration: 0.35s;
  -webkit-overflow-scrolling: touch;

  h2 {
    margin: 0;
    font-size: 17px;
    line-height: 26px;
    letter-spacing: normal;
    text-transform: none;

    // HACK: Selector to reach the money element next to the "SENT OFFER".
    .verb + span,
    .verb + .titleize + span {
      margin-left: 6px;
    }

    .titleize {
      display: none;
    }
  }

  .offer + .messages {
    margin-top: 4px;
  }

  .messages p + p {
    margin-top: 21px;
  }

  p,
  ul {
    margin: 0;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: normal;
    word-break: break-word;
    color: $activity-item-text-color;
    &.disclaimer {
      color: $colors--warning;
    }
  }

  .activity-log-item {
    padding: 0;
    margin: 0;

    &.me {
      text-align: right;
    }
  }

  .activity-log-item + .activity-log-item {
    margin-top: 12px;
  }

  .avatar-message-container {
    display: flex;
    align-items: flex-end;

    .avatar-container {
      margin-right: 12px;
    }
  }

  .activity-log-item-content {
    position: relative;
    display: inline-block;
    padding: 11px 22px 14px 15px;
    max-width: 230px;
    border-radius: 15px;
    border-width: 1px;
    border-style: solid;
    border-color: $activity-item-border-color;
    text-align: left;

    .tracking-number {
      text-decoration: underline;
    }

    &._purchased {
      border-color: $purchased-border-color;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      display: block;
      width: 15px;
      height: 15px;
      background-color: transparent;
    }

    &.them {
      background-color: $them-activity-item-background-color;
      border-bottom-left-radius: 0;
    }

    &.me {
      background-color: $me-activity-item-background-color;
      border-bottom-right-radius: 0;
    }

    &.system {
      border-radius: 15px;
    }

    &.bot {
      border: 1px solid $colors--info;
      background-color: $colors--new-tint;

      .-bot-message {
        .-sub-title {
          .-verb {
            color: $colors--info;
            font-weight: bold;
            vertical-align: middle;
          }

          .-badge {
            @include badge;

            &._bot {
              vertical-align: middle;
            }
          }

          .-verb + .-badge {
            margin-left: 5px;
          }
        }
      }

      .messages {
        & > * {
          white-space: pre-line;
        }

        a {
          color: $colors--blue-highlight;
        }

        ul {
          list-style: disc inside;

          li {
            margin-bottom: 0;
          }
        }
      }

      .-disclaimer {
        @include disclaimer;
      }

      .-bot-message + .messages,
      .messages + .-disclaimer {
        margin-top: 1em;
      }
    }

    .expiration-badge {
      display: none;
    }

    .verb.blue {
      color: $colors--info;
    }

    .-details {
      @include type--form-label;
      color: $colors--secondary-text;
      margin-top: 0.3rem;
    }
  }
}
