@import "../shared/z-index.scss";

@mixin photo-overlay-controls {
  display: flex;

  button {
    background: var(--colors--primary-text);
    opacity: 0.8;
    border: 0;
    color: var(--colors--white-background);
    width: 50%;
    padding: 0;
    margin-bottom: 0;
    border-radius: 0;
    z-index: $z-feed-settings-sticky;

    &:hover {
      background: var(--colors--success);

    }

    &:active {
      background: var(--colors--white-background);
      color: var(--colors--primary-text);
    }

    &.red {
      width: 100%;

      &:hover {
        background: var(--colors--alert);
      }
    }

    &.full-width {
      width: 100%;
    }
  }
}