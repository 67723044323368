@import "storefront/components/styles/pxToRem.module.scss";
@import "mixins/media-queries/index";

.button {
  padding: 0;
  width: pxToRem(36);
  height: pxToRem(36);

  .bookmark {
    width: pxToRem(15);
    height: auto;
    transition: transform 0.2s;
  }

  &:hover {
    .bookmark {
      transform: none;
      @include larger-than-phone {
        transform: scale(1.2);
      }
    }
  }

  &:active {
    .bookmark {
      transform: scale(1.2);
    }
  }
}
