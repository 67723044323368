@import "mixins/type/index";

.banner {
  @include type--body;
  -webkit-font-smoothing: antialiased;
  background-color: var(--colors--medium-stroke);
  box-sizing: border-box;
  color: var(--colors--white-background);
  font-weight: bold;
  height: auto;
  margin: 0;
  opacity: 1;
  padding: 10px 40px;
  position: relative;
  text-align: center;
  transition-delay: 0s, 0.25s;
  transition-duration: 0.25s, 0s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  width: 100%;

  &.alert {
    background-color: var(--colors--alert);
  }

  &.notice {
    background-color: var(--colors--success);
  }

  &.info {
    background-color: var(--colors--info);
  }

  &.dismissing {
    opacity: 0;
  }

  .link {
    color: var(--colors--white-background);
    text-decoration: underline;
    cursor: pointer;
  }

  .closeButton {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
}
