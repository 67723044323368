@import "../../../mixins/media-queries/index";

.conversation-item {
  $item-seperator-color: $colors--light-stroke;
  $active-item-border-color: $colors--medium-stroke;

  position: relative;
  display: block;
  padding: 25px 25px;
  border-bottom: 1px solid $item-seperator-color;

  &:not(.active) {
    cursor: pointer;
  }

  @include larger-than($phone-width) {
    padding: 20px 35px;
    display: flex;
    align-items: center;
    align-content: center;
  }

  &.active {
    padding: 0;
    margin: 20px 0;
    border: 1px solid $active-item-border-color;
    align-items: stretch;
    flex-direction: column;
    border-radius: 1.5px;
    box-shadow: 0px 1px 4px rgba(0,0,0,0.5);

    .conversation-listing-details-wrapper h2 {
      max-width: 80%;
    }
  }

  @import "./conversation-is-unread";
  @import "./conversation-listing-details-wrapper";
  @import "./conversation-last-action";
  @import "./conversation-details";
  @import "./conversation-badge";
  @import "./conversation-settings";
  @import "./conversation-item-cta";
  @import "./conversation-item-activity-log";
  @import "./conversation-listing-status-bar";
  @import "./conversation-item-activity-log-expander";
}

.conversation-item.active + .conversation-item {
  margin-top: 20px;
}
