@import "../../../mixins/media-queries/index";
@import "../../../mixins/type/index";
@import "../../../mixins/badge/index";
@import "../../../mixins/disclaimer/index";

.conversation-last-action {
  position: absolute;
  top: 55px;
  left: 98px;
  margin-right: 30px;
  width: 45%;

  &.-with-badge {
    top: 85px;
  }

  @include larger-than($phone-width) {
    position: static;
    top: 0;
    left: 0;
    flex: 2;
  }

  p,
  h2 {
    margin: 0;
    letter-spacing: normal;
    text-transform: none;
    overflow: hidden;
    white-space: nowrap;
    max-width: 77%;
    text-overflow: ellipsis;

    @include larger-than($phone-width) {
      max-width: unset;
    }
  }

  .expiration-badge {
    @include badge;
    background-color: $colors--bad-tint;
    border: 1px solid $colors--alert;
    color: $colors--alert;
    display: inline-block;
    margin-top: 5px;

    @include larger-than-phone {
      display: none;
    }
  }

  ._offer {
    @include larger-than-phone {
      font-weight: $type--weights--bold;
    }
  }

  .verb + span {
    margin-left: 5px;
  }

  .verb.blue {
    color: $colors--info;
  }

  .sub-title {
    margin: 0;
  }

  .-details {
    display: none;

    @include larger-than($phone-width) {
      display: block;
      @include type--body;
      color: $colors--secondary-text;
      margin-top: 0.5rem;
    }
  }

  .activity-log-item-content.bot {
    @include larger-than-tablet {
      border: 1px solid $colors--info;
      background-color: $colors--new-tint;
      border-radius: 9px 9px 9px 0px;
      padding: 15px;
    }

    .-bot-message {
      .-sub-title {
        .-verb {
          color: $colors--info;
          font-weight: bold;
          vertical-align: middle;
        }
        .-badge {
          @include badge;
        }

        .-verb + .-badge {
          margin-left: 5px;
        }
      }
    }

    .messages {
      display: none;

      @include larger-than-tablet {
        display: block;

        & > * {
          white-space: pre-line;
        }

        a {
          color: $colors--blue-highlight;
        }
      }
    }

    .-disclaimer {
      @include disclaimer;

      &._visibility {
        display: none;

        @include larger-than-tablet {
          display: inline-flex;
        }
      }
    }

    .-bot-message + .messages,
    .messages + .-disclaimer {
      margin-top: 0.5em;
    }
  }
}
