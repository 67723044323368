.conversation-is-unread {
  $background-color: $colors--info;

  position: absolute;
  left: 10px;
  top: 50%;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-top: -5px;
  background-color: $background-color;
}
