@import "../../../stylesheets/mixins/button/_index.scss";
@import "../../../stylesheets/mixins/sellform-photo-spinner.scss";

$phone-width: 667px;
@mixin phone {
  @media only screen and (min-width: 0) and (max-width: #{$phone-width}) {
    @content;
  }
}

@mixin sellform-photo-placeholder {
  &.spinning {
    @include sellform-photo-spinner;
  }
  svg {
    width: 30px;
    height: 30px;
    fill: $colors--secondary-text;
  }
  label {
    display: none;
  }
}

@mixin sellform-photo-remove-button {
  position: absolute;
  top: 0;
  right: 0;
  background: $colors--alert;
  border: 0;
  font-size: 20px;
  width: 20px;
  padding: 2px 2px 2px 4px;
  margin: 0px;
  height: 20px;
  line-height: 18px;
  cursor: pointer;

  button {
    border: 0px;
    cursor: pointer;
    span {
      padding-left: 2px;
    }
  }
}

@mixin sellform-photo-rotate-button {
  display: flex;
  max-width: 120px;
  bottom: 0px;
  left: 0px;
  position: absolute;
  height: 30px;

  button {
    border-radius: 0;
    border: 0px;
    height: 30px;
    width: 61.5px;
    cursor: pointer;
  }
}

.SellForm-Wrapper {
  .photos-container {
    &.error {
      border: 2px solid $colors--alert;
    }
    .hidden {
      display: none;
    }

    .global.error {
      margin-bottom: 0;
      margin-top: 20px;
    }

    h4 {
      margin-bottom: 19px;
      @include phone {
        display: none;
      }
    }

    .photos-widget-container {
      @include phone {
        padding: 20px 0;
        text-align: center;

        .labels {
          h4 {
            display: none;
          }
        }
      }
    }

    .mobile-photos-grid {
      text-align: center;
    }

    .mobile-file-button {
      @include phone {
        @include button;
        display: flex;
        justify-content: center;
        margin: 20px auto 0 auto;
        background: $colors--white-background;
        color: $colors--primary-text;
        border: 1px solid $colors--primary-text;
        font-family: $control-font;
        text-transform: uppercase;
        text-align: center;
        width: 255px;

        span {
          height: 36px;
          padding: 6px;
          display: block;
        }

        input {
          display: none;
        }
      }
    }

    .photos-grid {
      display: flex;

      @include phone {
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
      }

      .cover-photo-container {
        margin-right: 18px;
      }

      .other-photos-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }

    .photo {
      margin: 0px;
      padding: 0px;
      line-height: 0px;
      display: inline-block;
      margin-bottom: 12px;

      label {
        margin: 0px;
        display: inline;

        .image-container {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;
          background: $colors--off-white-background;

          // NOTE: these need to sync up with the JS image processing sizes
          &.placeholder {
            width: 123px;
            height: 164px;
            &.cover-photo {
              width: 255px;
              height: 340px;
            }
            @include sellform-photo-placeholder;
          }

          .remove-button {
            @include sellform-photo-remove-button;
          }

          .rotate-buttons {
            @include sellform-photo-rotate-button;
          }

          &.cover-photo {
            .rotate-buttons {
              max-width: 260px;
              button {
                padding: 0px;
                width: 127.5px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
