@import "storefront/components/styles/pxToRem.module.scss";

.label {
  font-weight: var(--font-weight--heavy);
  line-height: 1.3;

  &.sm {
    font-size: pxToRem(16);
  }

  &.lg {
    font-size: pxToRem(22);
  }

  &.xs {
    font-weight: var(--font-weight--regular);
    font-size: pxToRem(12);
    line-height: 1.5;
  }

  &.error:after {
    content: "⚠";
    font-weight: var(--font-weight--regular);
    margin-left: 0.296296296em;
    color: var(--colors--alert);
  }
}
