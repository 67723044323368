@import "../shared/z-index.scss";

@mixin onboarding-tooltip {
  @include type--form-label;
  position: absolute;
  display: flex;
  padding: 1rem;
  color: var(--colors--white-background);
  background-color: var(--colors--blue-highlight);
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  z-index: $z-tool-tips;

  &._hidden {
    visibility: hidden;
  }

  &::before {
    content: "\A";
    height: 3px;
    width: 0;
    border: 10px solid transparent;
    border-bottom-color: var(--colors--blue-highlight);
    position: absolute;
    top: -20px;
  }

  &._bottom-arrow::before {
    border-bottom-color: transparent;
    border-top-color: var(--colors--blue-highlight);
    top: initial;
    bottom: -20px;
  }

  &._left::before {
    left: 6px;
  }

  &._right::before {
    right: 6px;
  }

  &._black {
    background-color: var(--colors--primary-text);

    &::before {
      border-bottom-color: var(--colors--primary-text);
    }
  }

  &._light-gray {
    background-color: var(--colors--off-white-background);
    color: var(--colors--primary-text);

    &::before {
      border-bottom-color: var(--colors--off-white-background);
    }
  }
}
