.container {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background-color: var(--colors--white-background);
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.32, 0.72, 0, 1);
  padding-top: 4.8rem;
  min-width: min(335px, 100vw);

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}

.close {
  position: absolute;
  top: 1.6rem;
  z-index: 9999;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
}

.left {
  left: 0;
  .close {
    left: 1.6rem;
  }
  &[data-state="open"] {
    animation-name: slideFromLeft;
  }

  &[data-state="closed"] {
    animation-name: slideToLeft;
  }
}

.right {
  right: 0;
  .close {
    right: 1.6rem;
  }
  &[data-state="open"] {
    animation-name: slideFromRight;
  }

  &[data-state="closed"] {
    animation-name: slideToRight;
  }
}

@keyframes slideFromLeft {
  from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideToLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideFromRight {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideToRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}
