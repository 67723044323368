@import "mixins/media-queries/index";

.muteNotifications {
  background-color: transparent;
  border: none;
  padding: 0;
  height: 20px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  margin-top: 1px;

  &:hover {
    .bell {
      transform: none;
      @include larger-than-phone {
        transform: scale(1.2);
      }
    }
  }

  &:active {
    .bell {
      transform: scale(1.2);
    }
  }

  svg {
    transition: transform 0.2s;
    width: 16px;
    height: 16px;
  }

  &.newIcon {
    width: 2.4rem;
    height: 2.4rem;

    svg {
      width: 24px;
      height: auto;
    }
  }
}
