@import "../../../../mixins/type/index";
@import "../../../../mixins/grid/index";
@import "../../../../mixins/media-queries/index";

.Editorial--DropProductsPreviewModule {
  padding-top: 4rem;
  margin-bottom: 8rem;

  .--title {
    @include type--display-3;
    margin-bottom: 3rem;
    text-align: center;
  }

  .--products {
    display: grid;
    grid-template-columns: repeat(2, 49%);
    grid-auto-rows: auto;
    grid-gap: 1rem;
    justify-content: space-between;
    margin: 0 0rem 3rem 0rem;

    // NOTE(lg): this is a *HACK* to not let the mobile app users
    // load these preview listings - because if they do they will
    // be cached with invalid data.
    pointer-events: none;

    @include larger-than-phone {
      max-width: 1034px; // based on the main feed width
      margin: 0 auto 3rem auto;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      // NOTE(lg): unset the earlier hack - desktop is fine, we're just
      // targeting mobile with this one :/
      pointer-events: initial;
    }
  }

  .--dropDate {
    @include type--headline-2;
    margin-top: 4rem;
    text-align: center;
  }
}
