.metadata {
  padding: 1px 8px;
  -webkit-font-smoothing: antialiased;
}

.designerAndSize {
  display: flex;
  margin-bottom: 3px;
}

.designer {
  color: var(--colors--primary-text);
  flex: 1;
  margin-bottom: 0;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}

.size {
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}

.title {
  color: var(--colors--primary-text);
  margin: 0;
  padding-bottom: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem;
  font-size: 12px;
  line-height: 20px;
}
