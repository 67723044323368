@import "mixins/media-queries/index";

.authButton {
  height: 40px;
  width: 100%;
  font-size: 14px;

  &.facebook,
  &.apple,
  &.google {
    font-family: var(--base-fonts);
    font-weight: bold;
    text-transform: none;
  }

  &.facebook {
    &::before {
      content: "";
      background-image: url("https://media-assets.grailed.com/prd/misc/NK7GTHFL6TBYZGYUL3QTFVMR-A6F6I_QPIQIJW");
      background-position: 10%;
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: 15%;
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }

    @include larger-than-tablet {
      background-position: 15%;
    }

    background-color: var(--colors--facebook-blue);
    border-color: var(--colors--facebook-blue);
    box-sizing: border-box;
    color: var(--colors--white-background);
  }

  &.google,
  &.apple {
    margin-top: 12px;

    > svg {
      vertical-align: text-top;
      margin-right: 10px;
      width: 16px;
      height: 16px;
      display: inline-block;
    }
  }

  &.apple {
    > svg {
      path {
        fill: var(--colors--white-background);
      }
    }
  }
}
