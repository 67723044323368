#security-account {
  .account-sections.horizontal-choice {
    margin-bottom: 4rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  .Security {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .--title {
      @include type--display-3;
    }

    .--setup {
      .--password-form {
        @include field;

        .--label {
          text-align: left;
        }
      }

      .--button {
        @include button;
        margin-top: 5%;
      }
    }

    .--qr {
      margin-top: 5%;
    }
  }
}
