@import '../../../mixins/type/index';
@import '../../../mixins/button/index';

.Editorial--ExpositionModule {
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 50px;
  text-align: left;

  @include larger-than-tablet {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 100px;
    margin-top: 50px;
  }

  .-image-container {
    width: 90%;

    @include larger-than-tablet {
      width: 35%;
    }

    .-image {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
  }

  .-content {
    width: 90%;

    @include larger-than-tablet {
      width: 55%;
      padding: 0 5%;
    }
  }

  .-name {
    @include type--display-3;
    margin-bottom: 10px;

    @include larger-than-tablet {
      margin-bottom: 30px;
    }
  }

  .-sub-header {
    @include type--primary-link;
    font-weight: $type--weights--heavy;
    @include type--font-size-and-line-height(14px);

    @include larger-than-tablet {
      margin-bottom: 20px;
    }
  }

  .-description {
    p {
      @include type--font-size-and-line-height(16px);
      font-weight: $type--weights--light;

      @include larger-than-tablet {
        @include type--font-size-and-line-height(20px);
      }
    }
  }
}
