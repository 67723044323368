#offer .input-prepend {
  margin-bottom: 30px;

  input {
    text-align: center;
  }
}

.offer-types {

  display: flex;

  .binding-offer-help-tooltip {
    display: none;
    position: absolute;
    margin-bottom: 0;
    top: -66px;
    left: 37px;
    width: 260px;
  }

  .offer-type {
    flex: 1;

    padding: 30px 0;
    position: relative;
    cursor: pointer;

    label {
      display: flex;
      justify-content: center;

      position: relative;
      margin: 0;

      input, h3 {
        cursor: pointer;
        margin: 0;
      }
      input {
        margin-right: 10px;
      }
      h3 {
        font-weight: bold;
        line-height: 16px;
      }
    }

    &:hover .binding-offer-help-tooltip {
      display: block;
    }
  }


}

#offer {

  input {
    margin-bottom: 0;
  }

  a.button.purchase {

    height: 38px;
    line-height: 38px;
    margin-bottom: 0;

    span {
      margin-right: 5px;
    }

  }

}

@media only screen
  and (min-device-width: 0px)
  and (max-device-width: 1058px)
  and (-webkit-min-device-pixel-ratio: 2) {

  .binding-offer-help-tooltip {
    position: relative;
    width: 260px;
    padding: 1rem;
    text-align: center;
    margin-bottom: 0;
    border: none;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 3rem;

    .arrow {
      display: none;
    }
  }

}
