@import "../mixins/type/index";

.SocialShare {
  display: flex;
  justify-content: center;
  .--share-text {
    display: none;
    @include type--form-label;
    text-transform: uppercase;
    @include larger-than-tablet {
      display: inline-block;
    }
  }
  .twitter-share,
  .facebook-share {
    line-height: 1;
  }
  .twitter-share {
    margin-right: 14px;
  }
  .facebook-share {
    margin-right: 6px;
  }
}

#showlistingsocial {
  padding-bottom: 15px;
}

#showdesc .social-share-widget {
  padding-top: 5px;
  border-top: 1px solid #cbcbcb;
}

.purchase-social-share-widget {
  padding: 60px 0 0 0;
}

.wardrobe-social-share-widget {
  padding: 34px 0 0 0;
  text-align: center;
}

h3.social-share-widget {
  text-align: center;
  margin-bottom: 0;

  display: flex;
  align-items: center;

  & > span {
    display: block;
    margin-right: 5px;
  }

  .social-share-widget-item {
    display: inline;
    vertical-align: middle;
    margin-right: 5px;

    &:last-item {
      margin-right: 0;
    }

    img {
      height: 15px;
    }
  }
}

.wardrobe-link,
.twitter-share,
.twitter-share-button,
.facebook-share,
.facebook-share-button {
  cursor: pointer;
  svg {
    width: auto;
    fill: $colors--accent;
    body._grailed & {
      height: 16px;
    }
  }
}
.wardrobe-link {
  svg {
    path {
      fill: $colors--accent;
    }
  }
}
