@import "../../mixins/media-queries/index";
@import "../../mixins/loading";

.Module--FeaturedBlock {
  &._loading {
    @include loading(100%, 100%);
    min-height: 60vh;
    margin-bottom: 3rem;
  }

  @include larger-than-phone {
    &._loading {
      min-height: 70vh;
      margin-bottom: 6rem;
    }
  }

  @include larger-than-tablet {
    &._loading {
      @include loading(100%, 100%);
    }
  }
}
