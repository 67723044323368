.DevisePasswordForm,
.DeviseConfirmationForm {
  padding: 13px 0 0;
  max-width: 400px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .-title {
    @include type--headline-1;
    margin-bottom: 0px;
  }

  .-title + .-subtitle {
    margin-top: 10px;
  }

  .-subtitle,
  .-prompt {
    @include type--body;
  }

  .-field {
    @include field;
    text-align: left;
    width: 100%;
  }

  .-field + .-field {
    margin-top: 10px;
  }

  .-field + .-button {
    margin-top: 34px;
  }

  .-button {
    @include button;
    display: block;
    margin: 0 auto;
    text-transform: capitalize;
    font-size: 12px;
    width: 150px;
    margin-bottom: 22px;
  }

  .-button + a {
    display: block;
  }

  a {
    text-decoration: underline;
  }
}

.DevisePasswordForm {
  .-prompt {
    text-align: left;
    margin-bottom: 10px;
  }

  .-title + .-prompt {
    margin-top: 30px;
  }

  .-error-list {
    margin: 20px 0 30px;
    color: var(--colors--alert);
    text-align: left;

    .-error-message {
      margin-bottom: 0;
    }
  }
}
