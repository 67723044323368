@import "mixins/media-queries/index";

.header {
  position: relative;
  width: 100%;
  background-color: var(--colors--white-background);
  z-index: 220;
  height: 4.9rem;

  @include larger-than-tablet {
    height: auto;
    border-bottom: 1px solid var(--colors--light-stroke);
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}

.container {
  position: relative;
  padding: 0.6rem 1.6rem;
  max-width: 120rem;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  height: 4.9rem;

  @include larger-than-tablet {
    gap: 2.4rem;
    height: 7rem;
  }
}

.logo {
  position: absolute;
  @include larger-than-tablet {
    position: relative;
    order: 1;
  }
}

.menu {
  order: 1;
  @include larger-than-tablet {
    display: none;
  }
}

.actions {
  order: 3;
  margin-left: auto;
}

.search {
  order: 2;
  margin-right: auto;
  @include larger-than-tablet {
    flex: 1;
  }
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--colors--white-background);
  border-bottom: 1px solid var(--colors--light-stroke);
  z-index: 9999;
}

.merchandisingMenu {
  display: none;

  @include larger-than-tablet {
    display: block;
    max-width: 120rem;
    margin: 0 auto;
    width: 100%;
    padding-top: 71px;
  }
}
