@function fontPath($path) {
  @return "https://fonts.grailed.com/#{$path}";
}

///////////////
//   Video   //
///////////////

@font-face {
  font-family: "Video";
  src: url(fontPath("video/medium.woff2")) format("woff2");
  src: url(fontPath("video/medium.woff")) format("woff");
  src: url(fontPath("video/medium.otf")) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Video";
  font-weight: 600;
  src: url(fontPath("video/semibold.woff2")) format("woff2");
  src: url(fontPath("video/semibold.woff")) format("woff");
  src: url(fontPath("video/semibold.otf")) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Video";
  font-weight: 600;
  font-stretch: condensed;
  src: url(fontPath("video/condensed-semibold.woff2")) format("woff2");
  src: url(fontPath("video/condensed-semibold.woff")) format("woff");
  src: url(fontPath("video/condensed-semibold.otf")) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Video";
  font-weight: 700;
  font-stretch: condensed;
  src: url(fontPath("video/condensed-bold.woff2")) format("woff2");
  src: url(fontPath("video/condensed-bold.woff")) format("woff");
  src: url(fontPath("video/condensed-bold.otf")) format("opentype");
  font-display: swap;
}
