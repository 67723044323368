.conversation-listing-status-bar {
  padding: 10px 0;
  margin-bottom: 0;
  text-align: center;
  font-weight: bold;

  border-bottom: 1px solid $colors--medium-stroke;

  &.green {
    background-color: $colors--success;
    color: $colors--white-background;
  }

  &.warn {
    background-color: $colors--alert;
    color: $colors--white-background;
  }

  &.blue {
    background-color: $colors--blue-highlight;
    color: $colors--white-background;
  }

  &.safe-account-warning,
  &.offsite-warning {
    padding: 20px 60px 30px;
    line-height: 1.71;
    color: $colors--alert;
    font-size: 14px;
    font-weight: 500;
    font-family: $sub-title-font;
    text-transform: none;

    .warning-header {
      text-transform: uppercase;
      font-weight: 600;
      font-family: $control-font;
    }

    .reach-out {
      color: $colors--primary-text;
    }

    br {
      line-height: 3;
    }
  }

  .-link {
    color: $colors--white-background;
    text-decoration: underline;
  }
}
