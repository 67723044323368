._rotate-down {
  transform: rotate(0);
}

._rotate-left {
  transform: rotate(90deg);
}

._rotate-right {
  transform: rotate(270deg);
}

._rotate-up {
  transform: rotate(180deg);
}
