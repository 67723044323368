@import "../type/index";

@mixin button {
  @include type--button;
  background-color: var(--colors--white-background);
  border: 1px solid var(--colors--light-stroke);
  box-shadow: none;
  box-sizing: border-box;
  color: var(--colors--primary-text);
  cursor: pointer;
  display: inline-block;
  height: auto;
  margin: 0;
  padding: 9px 13px;
  text-align: center;
  transition: background-color 250ms ease, border-color 250ms ease,
    color 250ms ease;
  width: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  // Sizes

  &._small {
    padding: 4px 11px;

    .-icon {
      margin-right: 5px;

      svg {
        height: 12px;
        width: 12px;
      }
    }
  }

  &._medium {
    padding: 9px 15px;

    .-icon {
      margin-right: 7px;

      svg {
        height: 12px;
        width: 12px;
      }
    }
  }

  &._large {
    padding: 14px 25px;

    .-icon {
      float: left;
      margin: 0 10px 0 -7px;

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }

  &._x-large {
    @include type--font-size-and-line-height(14px);
    padding: 17px 41px;

    .-icon {
      float: left;
      margin: 0 14px 0 -15px;

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }

  // States

  &:hover {
    background-color: var(--colors--off-white-background);
    border-color: var(--colors--primary-text);

    .-icon {
      svg path {
        fill: var(--colors--primary-text);
      }
    }
  }

  &:active {
    background-color: var(--colors--light-stroke);
    border-color: var(--colors--primary-text);
    color: var(--colors--primary-text);

    svg path {
      fill: var(--colors--primary-text);
    }
  }

  &._border {
    border: 1px solid var(--colors--primary-text);
  }

  &._strong {
    border: 1px solid var(--colors--primary-text);
    background-color: var(--colors--primary-text);
    color: var(--colors--white-background);

    svg path {
      fill: var(--colors--white-background);
    }

    &:hover,
    &:active {
      background-color: var(--colors--gray-banner);
      border-color: var(--colors--gray-banner);
    }

    &:focus {
      background-color: var(--colors--primary-text);
      border-color: var(--colors--primary-text);
    }
  }

  &._super {
    border: 1px solid var(--colors--blue-highlight);
    background-color: var(--colors--blue-highlight);
    color: var(--colors--white-background);

    svg path {
      fill: var(--colors--white-background);
    }

    &:hover,
    &:active {
      background-color: var(--colors--info);
      border-color: var(--colors--info);
    }

    &:focus {
      background-color: var(--colors--blue-highlight);
      border-color: var(--colors--blue-highlight);
    }
  }

  &._alert {
    border: 1px solid var(--colors--alert);
    color: var(--colors--alert);

    svg path {
      fill: var(--colors--white-background);
    }

    &:hover,
    &:active,
    &:focus {
      background-color: var(--colors--alert);
      color: var(--colors--white-background);
    }
  }

  &._destructive {
    border: 1px solid var(--colors--alert);
    background-color: var(--colors--alert);
    color: var(--colors--white-background);

    svg path {
      fill: var(--colors--white-background);
    }

    &:hover,
    &:active {
      background-color: var(--colors--hover-alert);
      border-color: var(--colors--hover-alert);
    }

    &:focus {
      background-color: var(--colors--alert);
      border-color: var(--colors--alert);
    }
  }

  &._disabled,
  &[disabled] {
    background-color: var(--colors--off-white-background);
    border: 1px solid var(--colors--light-stroke);
    color: var(--colors--tertiary-text);
    cursor: not-allowed;

    svg path {
      fill: var(--colors--tertiary-text);
    }

    &:hover,
    &:active {
      background-color: var(--colors--light-stroke);
      border: 1px solid var(--colors--light-stroke);
      color: var(--colors--tertiary-text);
    }
  }

  &._descriptive {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 34px 26px;
    text-align: left;
    width: 400px;

    .-info {
      .-title {
        @include type--font-size-and-line-height(18px);
        text-transform: none;
      }

      .-subtitle {
        color: var(--colors--secondary-text);
        text-transform: none;
      }
    }

    .-action {
      align-self: center;
      color: var(--colors--primary-text);
    }

    &:hover,
    &:active {
      background-color: var(--colors--off-white-background);
      color: var(--colors--primary-text);
    }

    &:focus {
      background-color: var(--colors--white-background);
      border-color: var(--colors--light-stroke);
      color: var(--colors--primary-text);
    }
  }

  &._paypal {
    background-color: var(--colors--paypal-dark-blue);
    border-color: var(--colors--paypal-dark-blue);
    color: var(--colors--white-background);
    padding: 0;
    width: 300px;

    .-cta {
      box-sizing: border-box;
      display: inline-block;
      padding: 9px 13px;
      width: 87%;

      svg {
        margin: 4px 0 0 6px;
        vertical-align: top;

        g g path {
          fill: var(--colors--white-background);
        }
      }
    }

    .-action {
      border-left: 1px solid rgba(var(--colors--white-background), 0.2);
      box-sizing: border-box;
      display: inline-block;
      padding: 9px 13px;
      width: 13%;

      svg {
        width: 8px;

        path {
          fill: var(--colors--white-background);
        }
      }
    }

    &:hover,
    &:active {
      background-color: var(--colors--paypal-light-blue);
      border-color: var(--colors--paypal-light-blue);
    }

    &:focus {
      background-color: var(--colors--paypal-dark-blue);
      border-color: var(--colors--paypal-dark-blue);
    }
  }

  .-icon {
    display: inline-block;

    svg {
      transform: translateY(-1px);
      vertical-align: middle;
    }
  }

  &._mobile-web {
    &._clear {
      transform: translateX(-2px);

      svg {
        vertical-align: middle;
        transform: translateY(-1px);

        path {
          stroke: var(--colors--alert);
        }
      }
    }

    &:hover,
    &:focus {
      border-color: var(--colors--light-stroke);
    }

    &:active {
      background-color: var(--colors--white-background);
      border-color: var(--colors--light-stroke);
      color: var(--colors--primary-text);
    }
  }

  &._tab-nav {
    @include type--font-size-and-line-height(14px);
    background-color: var(--colors--off-white-background);
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    color: var(--colors--secondary-text);
    min-width: 125px;
    padding: 21px 20px;
    text-transform: none;

    &:hover {
      color: var(--colors--primary-text);
    }

    &.-active,
    &:active {
      font-weight: 800;
      background-color: var(--colors--white-background);
      border-bottom: 2px solid var(--colors--primary-text);
      color: var(--colors--primary-text);
    }
  }

  &._tab-button {
    @include type--font-size-and-line-height(14px);
    background-color: var(--colors--off-white-background);
    color: var(--colors--secondary-text);
    min-width: 125px;
    padding: 21px 20px;

    &:hover {
      color: var(--colors--primary-text);
    }

    &.-active,
    &:active {
      background-color: var(--colors--white-background);
      border-color: var(--colors--primary-text);
      color: var(--colors--primary-text);
    }
  }

  &._loading {
    cursor: progress;

    &::after {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-left: 10px;
      vertical-align: -2px;
      border-radius: 100%;
      border: 1px solid;
      border-left-color: transparent;
      color: inherit;
      animation: rotate 2s forwards linear infinite;

      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }
    }

    &._x-large {
      &::after {
        margin-left: 14px;
      }
    }
  }

  &._round {
    background-color: transparent;
    border: 1px solid var(--colors--primary-text);
    border-radius: 50px;
    text-transform: none;

    &:hover {
      background-color: var(--colors--primary-text);
      color: var(--colors--white-background);
    }

    &._strong {
      border: 1px solid var(--colors--white-background);
      font-weight: 400;

      &:hover {
        background-color: var(--colors--white-background);
        border: 1px solid var(--colors--primary-text);
        color: var(--colors--primary-text);
      }

      &[disabled] {
        border: 1px solid var(--colors--light-stroke);
      }
    }

    &._super {
      border: 1px solid var(--colors--blue-highlight);
      background-color: var(--colors--blue-highlight);
      color: var(--colors--white-background);
      font-weight: 400;

      &:hover {
        background-color: var(--colors--info);
        border-color: var(--colors--info);
      }

      &[disabled] {
        border: 1px solid var(--colors--light-stroke);
      }
    }

    &._destructive {
      border: 1px solid var(--colors--alert);
      color: var(--colors--alert);
      font-weight: 400;

      &:hover {
        background-color: var(--colors--hover-alert);
        border-color: var(--colors--hover-alert);
        color: var(--colors--white-background);
      }

      &[disabled] {
        border: 1px solid var(--colors--light-stroke);
        color: var(--colors--light-stroke);
      }
    }

    &._small {
      padding: 4px 18px;
    }

    &._medium {
      padding: 9px 30px;
    }

    &._large {
      padding: 14px 45px;
    }

    &._x-large {
      padding: 17px 60px;
    }
  }
}

@mixin button--homepage-cta {
  @include button;
  padding: 4px 12px;
}

@mixin button--all-styles-removed {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: default;
  outline: inherit;
}
