@import "mixins/type";
@import "mixins/media-queries";
@import "../../../app/assets/stylesheets/shared/media_queries";

div#designers.index {
  margin-top: 38px;
}

.designer-directory-wrapper {
  .-title {
    @include type--display-4;
    text-align: center;
    margin-bottom: 35px;
  }

  .designer-group {
    display: flex;
    flex-direction: column;

    min-width: 33%;
    max-width: 33%;
  }

  a.designer {
    @include type--body;
    overflow: ellipsis;
    margin-bottom: 3px;

    .count {
      margin-left: 3px;
      visibility: hidden;
    }

    &:hover {
      font-weight: $type--weights--bold;

      .count {
        visibility: visible;
      }
    }
  }

  .designer-spotlight-wrapper {
    height: 159px;
    margin-bottom: 62px;
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; // momentum scroll for safari mobile
    padding-bottom: 20px;
    @include larger-than-tablet {
      padding-bottom: 0;
    }

    &::-webkit-scrollbar {
      display: hidden;
      height: 0;
      -webkit-appearance: none; // hide scrollbar for safari
    }

    .designer-spotlight {
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 20px;

        @include larger-than-tablet {
          margin-left: 0;
        }
      }

      .designer-spotlight-link {
        display: block;
        margin-bottom: 4px;

        .-logo {
          height: 120px;
          width: 120px;
          border: 1px solid var(--colors--light-stroke);
        }
      }

      span.count {
        @include type--body;
      }
    }
  }

  .designer-directory {
    padding: 0 20px;
    @include larger-than-laptop {
      padding: 0;
    }

    .alphabet {
      display: flex;
      justify-content: space-between;

      padding-bottom: 18px;
      margin-bottom: 26px;
      border-bottom: 1px solid var(--colors--light-stroke);

      span {
        @include type--display-4;
        cursor: pointer;
        text-transform: uppercase;
      }
    }

    .designers-loading {
      height: 1000px;
    }

    .refine-and-search-wrapper {
      display: flex;
      align-items: center;

      margin-bottom: 74px;

      .refinements {
        display: flex;
        margin-right: auto;
      }

      h2,
      label {
        margin-bottom: 0;
      }

      h2 {
        @include type--large-link;
        margin-right: 40px;
        cursor: pointer;

        &.active {
          font-weight: $type--weights--bold;
        }
        &:not(.active) {
          color: var(--colors--secondary-text);
        }
        &:last-of-type {
          margin-right: auto;
        }
      }

      form {
        margin: 0;

        .search-magnifier {
          left: 10px;
          top: 17px;
        }

        .search-clear {
          top: 9px;
        }

        input {
          @include type--base;
          @include type--font-size-and-line-height(12px);
          padding: 12px 8px 12px 30px;
          box-sizing: border-box;
          border: 1px solid var(--colors--light-stroke);
          width: 690px;

          &:focus {
            border: 1px solid var(--colors--light-stroke);
          }
        }
      }
    }
  }

  .drop-cap-list-wrapper {
    .drop-cap {
      @include type--display-1;
      text-transform: uppercase;
      margin-right: 60px;
      width: 100px;
    }

    .drop-cap-group {
      display: flex;
      margin-bottom: 120px;

      .designers {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
      }
    }
  }

  .normal-list-wrapper {
    display: flex;
    flex-wrap: wrap;

    margin-left: 160px;

    .designer {
      min-width: 33%;
      max-width: 33%;
    }
  }
}

@include phone-and-tablet {
  .designer-directory-wrapper {
    .designer-group {
      max-width: none;
    }

    .designer-spotlight-wrapper {
      margin-bottom: 12px;

      .row {
        flex-direction: column;

        .designer-spotlight {
          margin-right: 0px;
          margin-bottom: 40px;
        }
      }
    }

    .designer-directory {
      .alphabet {
        display: none;
      }

      .refine-and-search-wrapper {
        flex-direction: column-reverse;
        margin-top: 40px;

        .refinements {
          justify-content: space-between;
          width: 100%;
          margin-right: 0;
        }

        h2 {
          margin-right: 0px;

          &:last-of-type {
            margin-right: 0px;
          }
        }

        form {
          width: 100%;
          margin-bottom: 28px;

          input {
            width: 100%;
          }
        }
      }

      .normal-list-wrapper {
        flex-direction: column;
        margin-left: 74px;
      }

      .drop-cap-list-wrapper {
        .drop-cap {
          min-width: 40px;
          margin-right: 34px;
        }

        .drop-cap-group {
          margin-bottom: 54px;

          .designers {
            min-width: auto;
            max-width: none;
          }
        }
      }
    }
  }
}
