.emailForm {
  max-width: 100%;

  label,
  p,
  div {
    max-width: 100%;
  }
}

.field {
  display: flex;
  flex-direction: column;

  :global(.--label) {
    text-align: left;
    font-weight: bold;
  }

  :global(.--input) {
    line-height: unset;
  }

  :global(.--error) {
    color: red;
    font-size: 12px;
  }
}

.button {
  margin-bottom: 20px;
}
