@import "../styles/pxToRem.module.scss";

.button {
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-align: center;
  transition: background-color 250ms ease, border-color 250ms ease,
    color 250ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 0.03em;
  line-height: 1.3;
  font-family: "Video", monospace;
  text-transform: uppercase;
}

/////////////
//  Sizes  //
/////////////

.large {
  font-size: #{pxToRem(16)};
  padding: var(--spacing-12) var(--spacing-16);
  min-width: 200px;
}

.medium {
  font-size: #{pxToRem(11)};
  letter-spacing: 0.04em;
  padding: 12.5px var(--spacing-12);
  min-width: 90px;
}

.small {
  font-size: #{pxToRem(11)};
  padding: var(--spacing-6) var(--spacing-12);
  min-width: 90px;
}

////////////////
//  Variants  //
////////////////
.primary {
  background-color: var(--colors--primary-text);
  color: var(--colors--white-background);
  border-color: var(--colors--primary-text);

  &:hover {
    background-color: var(--colors--gray-banner);
    color: var(--colors--white-background);
    border-color: var(--colors--gray-banner);
  }
}

.secondary {
  background-color: var(--colors--white-background);
  color: var(--colors--primary-text);
  border-color: var(--colors--light-stroke);

  &:hover {
    background-color: var(--colors--off-white-background);
    border-color: var(--colors--primary-text);
  }
}

.important {
  background-color: var(--colors--blue-highlight);
  color: var(--colors--white-background);
  border-color: var(--colors--blue-highlight);
}

.important-secondary {
  background-color: var(--colors--white-background);
  color: var(--colors--blue-highlight);
  border-color: var(--colors--blue-highlight);
}

.destructive {
  background-color: var(--colors--alert);
  color: var(--colors--white-background);
  border-color: var(--colors--alert);

  .small {
    background-color: var(--colors--white-background);
    color: var(--colors--alert);
  }
}

.alert {
  background-color: var(--colors--white-background);
  border-color: var(--colors--alert);
  color: var(--colors--alert);

  &:hover {
    background-color: var(--colors--alert);
    color: var(--colors--white-background);
  }
}

.disabled,
.disabled:hover,
.disabled:active {
  background-color: var(--colors--light-stroke);
  color: var(--colors--secondary-text);
  border-color: var(--colors--light-stroke);
  cursor: not-allowed;
}

.tertiary,
.tertiary-destructive {
  padding: 0;
  min-width: 0;
  text-decoration: underline;
  font-weight: 400;
  &._small {
    font-size: #{pxToRem(12)};
    line-height: 1.5;
  }
}

.tertiary {
  color: var(--colors--primary-text);

  &:hover {
    color: var(--colors--blue-highlight);
  }

  &:active {
    color: var(--colors--primary-text);
  }
}

.tertiary-destructive {
  color: var(--colors--alert);
}

///////////////
//  Loading  //
///////////////
.loading {
  cursor: progress;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    background-color: inherit;
    opacity: 0.8;
  }

  &:after {
    z-index: 3;
  }

  .small::after {
    width: 16px;
    height: 16px;
    border-width: 2px;
  }

  &.large::after {
    width: 20px;
    height: 20px;
    border-width: 3px;
  }
}

.loading::after {
  content: "";
  position: absolute;
  box-sizing: border-box;
  display: inline-block;
  background-color: currentColor;
  border: 0 solid currentColor;
  background: transparent;
  border-bottom-color: transparent;
  border-radius: 100%;
  animation: loading-rotate 0.75s linear infinite;

  @keyframes loading-rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
