@import "../../mixins/media-queries/index";

.horizontal-choice {
  $link-text-color: $colors--gray-banner;
  $selected-link-text-color: $colors--primary-text;

  padding: 0;
  margin: 0;

  a {
    margin-right: 0;
    font-weight: bold;
    color: $link-text-color;
    cursor: pointer;
    transition: .1s ease-in color;

    &.selected {
      color: $selected-link-text-color;

      h3 {
        color: inherit;
      }
    }

    h3 {
      margin-bottom: 0;
      cursor: inherit;
      color: inherit;
    }
  }

  a + a {
    margin-left: 1.6rem;

    @include larger-than($phone-width) {
      margin-left: 5rem;
    }
  }
}
