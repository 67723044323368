@import "../../../app/assets/stylesheets/shared/config/_helpers.scss";
@import "mixins/loading";
@import "mixins/media-queries/index";
@import "mixins/photo-overlay-controls.scss";
@import "mixins/type/index";
@import "mixins/badge/index";
@import "mixins/button/index";

.carousel-listing-item {
  width: 170px;
  margin: 0 10px 40px 0;
  position: relative;
  max-width: 240px;
  transition: margin 0.1s linear, max-width 0.2s linear;

  @include larger-than-tablet {
    width: 192px;
  }
}

.feed-item {
  position: relative;
  width: calc(50% - 8px);
  margin-bottom: 16px;
  transition: margin 0.1s linear, max-width 0.2s linear;

  @include larger-than-phone {
    width: calc(100% / 3 - 10px);
  }

  @include larger-than-tablet {
    width: calc(100% / 4 - 12px);
    margin-bottom: 40px;
  }
}

.carousel-listing-item,
.feed-item {
  .listing-item-link {
    color: var(--colors--primary-text);
    text-decoration: none;
  }
  &.empty-item {
    margin: 0 1px 40px 1px;
  }

  .listing-cover-photo {
    align-items: center;
    display: flex;
    height: 0;
    width: auto;
    min-height: 0;
    max-height: 0;
    overflow: hidden;
    padding-top: 120%; // Adjusts aspect of picture / card
    position: relative;
    transition: width 0.2s linear, height 0.2s linear;
    background-color: var(--colors--off-white-background);

    img {
      height: 100%;
      width: 100%;
      min-height: 100%;
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      transition: max-width 0.2s linear, max-height 0.2s linear;
    }

    &.sold img {
      opacity: 0.5;
    }

    .hug-top,
    .hug-bottom {
      position: absolute;
      left: 0;
      right: 0;
      z-index: $z-feed-listing-card-admin-overlay;

      & > * {
        display: none;
      }
    }

    .hug-top {
      top: 0;
    }

    .hug-bottom {
      bottom: 0;
    }
  }

  .-overlay {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    z-index: $z-feed-listing-card-overlay;
    padding: var(--spacing-6);
  }

  &.hide {
    display: none;
  }

  .-feed-admin-actions {
    .-button {
      @include button;
      width: 100%;
      line-height: 15px;
    }
  }

  &:hover {
    .listing-cover-photo {
      .hug-top,
      .hug-bottom {
        & > * {
          display: flex;
        }

        .-feed-admin-actions {
          display: block;
        }
      }
    }
  }

  .-seller-buttons,
  .photo-rotate-buttons-container,
  .photo-change-cover-photo-buttons-container {
    @include photo-overlay-controls;

    .ListingDeleteToggle {
      width: 100%;

      button {
        width: 100%;

        &:hover {
          background: var(--colors--alert);
        }
      }
    }
  }

  .user-transaction-count-bubble {
    background-color: var(--colors--primary-text);
    border-radius: 100px;
    border: 2px solid var(--colors--white-background);
    color: var(--colors--white-background);
    font-family: var(--control-font-semibold);
    font-size: 12px;
    padding: 0 5%;
    position: absolute;
    right: 5%;
    top: 2%;
    z-index: $z-base-value;

    &.red {
      background-color: var(--colors--alert);
    }

    &.orange {
      background-color: var(--colors--warning);
    }
  }

  .heart {
    transition: transform 0.3s;

    &.filled {
      g {
        fill: var(--colors--primary-text);
        stroke: var(--colors--primary-text);
      }
    }

    &.unfilled {
      g {
        fill: none;
        stroke: var(--colors--primary-text);
      }
    }

    &:hover {
      transform: none;
      @include larger-than-phone {
        transform: scale(1.2);
      }
    }

    &:active {
      transform: scale(1.2);
    }
  }

  .heart-follow {
    display: inherit;
    padding: 7px;
  }

  .transaction-status {
    padding: 6px 0 6px 8px;
  }
}

.feed-item.closet {
  .listing-cover-photo.sold img {
    opacity: 1;
  }

  .listing-age {
    display: none;

    + .listing-metadata {
      margin-top: 6px;
    }
  }
}
