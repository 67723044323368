@import "../../../app/assets/stylesheets/mixins/field/index";
@import "../../../app/assets/stylesheets/mixins/type/index";
@import "../../../app/assets/stylesheets/mixins/checkbox/index";

.Form {
  padding: 15px;
  overflow-y: auto;
  margin-bottom: 15px;

  & > &Title + &Field {
    margin-top: 20px;
  }
  & > &Field + &Field {
    margin-top: 20px;
  }
  & > &Field + &Submit {
    margin-top: 20px;
  }

  &Title {
    @include type--title;
    margin-left: 10px;
    margin-bottom: 1rem;
  }

  &Field {
    @include field;
    @include checkbox;
    width: 100%;
    box-sizing: border-box;
    padding-left: 0;
  }

  &Submit {
    width: 100%;
  }

  &StrataFields {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
  }
}
