#history {
  margin: 0;

  .account-sections.horizontal-choice {
    margin-bottom: 4rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  h2.header {
    margin-bottom: 4rem;
    font-weight: bold;
  }

  .feed {
    width: 436px;

    .feed-item {
      margin-right: 2%;
      width: 48%;

      .listing-cover-photo {
        width: 100%;
        height: 286px;

        img {
          width: 100%;
          height: auto;
          max-height: 286px;
        }
      }
    }
  }

  .two-column-container {
    display: flex;
    justify-content: space-between;

    .bought {
      .row {
        margin-right: 0 1rem;
      }
    }

    .bought,
    .sold {
      .header {
        text-align: center;
      }
    }
  }

  .sold {
    opacity: 1 !important;
  }
}
