@import "../../../mixins/media-queries/index";
@import "../../../mixins/type/index";
@import "../../../mixins/disclaimer/index";

.conversation-item-activity-log {
  $background-color: $colors--off-white-background;
  $border-color: $colors--medium-stroke;

  .activity-log-item {
    display: flex;
    padding: 22px 80px;

    &.me {
      background-color: $background-color;
    }

    .tracking-number {
      text-decoration: underline;
    }
  }

  .activity-log-item + .activity-log-item {
    border-top: 1px solid $border-color;
  }

  .activity-log-item-sender {
    display: flex;
    flex-direction: row;
    width: 256px;
    margin-right: 24px;
    font-weight: bold;

    .avatar-container {
      position: relative;
      flex: none;
      margin-right: 30px;
    }

    .sub-title {
      text-transform: none;
    }
    .sub-title + .sub-title {
      margin-top: 1rem;
    }
  }

  .activity-log-item-content {
    flex: 1;
    width: 490px;
    margin-right: 42px;

    .messages {
      word-break: break-word;
      .disclaimer {
        color: $colors--warning;
      }
    }

    &.bot {
      .messages {
        ul {
          list-style: disc inside;

          li {
            margin-bottom: 0;
          }
        }
      }
    }

    p {
      line-height: 1.8rem;
      @include larger-than($phone-width) {
        letter-spacing: 0.035px;
      }
      margin-bottom: var(--spacing-12);
    }

    .offer {
      .sub-title + .sub-title {
        margin-top: 1rem;
      }

      ._offer {
        font-weight: $type--weights--bold;
      }

      .verb {
        font-style: italic;
      }

      .verb.green {
        line-height: 1.8rem;
      }

      .verb.blue {
        color: $colors--info;
      }

      span + span {
        margin-left: 8px;
      }

      .-details {
        @include type--body;
        color: $colors--secondary-text;
        margin-top: 0.5rem;

        span + span {
          margin-left: 0;
        }
      }

      .expiration-badge {
        display: none;
      }
    }

    &.bot {
      border: 1px solid $colors--info;
      background-color: $colors--new-tint;
      border-radius: 9px 9px 9px 0px;
      padding: 15px;

      .-bot-message {
        display: none;
      }

      .-disclaimer {
        @include disclaimer;
      }

      .messages {
        & > * {
          white-space: pre-line;
        }

        a {
          color: $colors--blue-highlight;
        }
      }

      .messages + .-disclaimer {
        margin-top: 1em;
      }
    }
  }
}
