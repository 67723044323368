@import '../../../mixins/type/index';
@import '../../../mixins/button/index';

.Ad--Landing {
  .--Key-Points {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10%;
    margin-bottom: 60px;
    
    @include larger-than-tablet {
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-bottom: 100px;
    }
    
    .--button {
      @include button;
      
      @include larger-than-phone {
        &._x-large {
          width: 310px;
        }
      }
    }
    
    .--key-point {
      text-align: center;
      margin-bottom: 60px;
      
      @include larger-than-tablet {
        width: 33%;
      }
      
      .--image {
        height: 55px;
        width: 55px;
        margin-bottom: 20px;
      }
      
      .--title {
        @include type--display-2;
        
        @include larger-than-tablet {
          @include type--headline-2;
          padding: 0 10%;
        }
      }
      
      .--description {
        font-weight: $type--weights--light;
        margin-bottom: 0;
        padding: 0 10%;
      }
      
      .--link {
        @include type--body;
        font-weight: $type--weights--light;
        color: $colors--info;
      }
    }
  }
}