.Ad--Landing {
  .--Collage {
    padding: 0 5%;
    @include larger-than-tablet {
      padding: 0 10%;
    }
    
    .--image {
      width: 100%;
    }
  }
}