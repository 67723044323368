.root {
  display: flex;
  align-items: center;
  width: 100%;

  & > div {
    width: 100%;
  }
}

.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0 1.6rem;
  width: 100%;
}

.item {
  padding: 1.5rem 0;
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
  gap: 0.4rem;
  transition: background-color 200ms ease-in-out;
  background-color: var(--colors--white-background);
  &:hover {
    color: var(--colors--blue-highlight);
  }
}

.trigger {
  &[data-state="open"], &[data-active="true"] {
    color: var(--colors--blue-highlight);
    .caret {
      transform: rotate(180deg);
    }
  }
}

.caret {
  transition: transform 150ms ease-in-out;
}

.viewportContainer {
  position: absolute;
  top: 11.4rem;
  left: 0;
  right: 0;
  display: flex;
}

.viewport {
  position: relative;
  margin-top: 0.375rem;
  height: var(--radix-navigation-menu-viewport-height);
  width: 100%;
  overflow: hidden;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, .1),
    0 4px 6px -4px rgba(0, 0, 0, .1);
  background-color: var(--colors--white-background);
  transition:
      width,
      height,
      300ms ease;
}

.content {
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
}

.hidden {
  display: none;
}
