@import "./sellform/photos";
@import "../mixins/type/index";
@import "../mixins/media-queries/index";
@import "./sell-form/index";

// the outer class is applied when the body reacts to a 'dragenter' event
// causing the overlay to appear - the overlay is responsible for capturing
// the dropped files
body.SellForm-Drag-Photo {
  #SellForm .SellForm-Wrapper .SellForm-Form-Wrapper .overlay {
    display: block;
  }
}

#SellForm {
  margin-top: 0px; // overrides `body > .container > div` styling

  .SellForm-Wrapper {
    width: 90%;
    margin: 0 auto;
    margin-top: 2em;
    font-weight: normal;

    @include larger-than-tablet {
      margin-top: 3em;
      width: 800px;
    }

    .SellForm-Form-Wrapper {
      div,
      span,
      label {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }

      font-weight: normal;

      .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.9);
        z-index: $z-general-overlay;
        display: none;
      }

      .section {
        margin-bottom: 5rem;

        @include larger-than-tablet {
          margin-bottom: 6rem;
        }
      }

      form {
        .SellFormInput,
        select,
        textarea {
          width: 100%;

          border-radius: 1.5px;
          cursor: pointer;

          &.error {
            border: 2px solid $colors--alert;
            font-style: italic;
          }

          &.hidden {
            display: none;
          }
        }

        .error {
          input,
          textarea {
            font-style: italic;
            border: 2px solid $colors--alert;
          }
        }

        .--accordion-section {
          display: flex;
          align-items: center;
          min-height: 75px;

          border-bottom: 1px solid $colors--light-stroke;
        }

        .--accordion-section {
          justify-content: space-between;

          .--label {
            @include type--title;
          }
        }

        input[type="text"]:not(.NO_GLOBAL_STYLE),
        input[type="number"],
        select {
          padding-left: 20px;
        }

        input[type="email"],
        .money {
          margin-bottom: 1rem;
        }

        .Currency {
          margin: 20px 0;
        }

        .-condition-container,
        .-trait-container {
          @include larger-than-tablet {
            margin-bottom: 6rem;
            max-width: 375px;
          }

          .-header-container {
            display: flex;
          }

          select,
          input {
            display: block;
            margin-bottom: 0;
          }

          .NewStamp {
            margin-left: 12px;
            display: inline-block;
            line-height: 19px;
            margin-top: 1px;
          }

          .instruction {
            margin-top: 2rem;
          }
        }

        .disabled {
          pointer-events: none;
          background-color: #f5f5f5;
        }

        .global.error {
          text-align: center;
          color: $colors--alert;
          font-style: italic;
          margin-bottom: 20px;

          li {
            list-style-type: none;
          }
        }
      }
    }
  }
}
