@import "../type/index";

@mixin badge {
  @include type--form-label;
  display: inline-block;
  border-radius: 20px;
  padding: 0px 8px;
  border: 1px solid var(--colors--primary-text);
  background-color: var(--colors--white-background);
  color: var(--colors--primary-text);
  cursor: default;

  &._strong {
    background-color: var(--colors--primary-text);
    color: var(--colors--white-background);
  }

  &._warning {
    background-color: var(--colors--alert-background);
    border: 1px solid var(--colors--alert);
    color: var(--colors--alert);
  }

  &._bot {
    border: none;
    color: var(--colors--new-tint);
    font-size: 10px;
    padding: 2px 5px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    background-color: var(--colors--secondary-text);
    border-radius: 3px;
    font-weight: bold;
    vertical-align: middle;
    line-height: initial;
  }
}
