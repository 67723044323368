@import "../../../../../mixins/media-queries/index";

.Editorial--DropProductsModule--Active--RowsLayout {
  position: relative;
  display: grid;
  grid-template-columns: 49% 49%;
  grid-auto-rows: auto;
  grid-row-gap: 4rem;
  grid-column-gap: 2%;
  margin: 0 0rem 4rem 0rem;

  @include larger-than-phone {
    max-width: 1034px; // grabbed from the main feed width
    margin: 0 auto 4rem auto;
    grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
  }

  .carousel-listing-item {
    width: 100%;
  }

  .Editorial--DropProductsModule--Active--SoldOut {
    grid-column: 1 / span 2;
    grid-row: 5 / span 1;
    text-align: center;
    width: calc(100% - 2rem);
    margin: auto;
    margin-bottom: 3rem;

    @include larger-than-phone {
      grid-column: 1 / span 4;
      grid-row: 3 / span 1;
      max-height: fit-content;
    }
  }
}
