@import "../../../mixins/media-queries/index";

.conversation-details {
  $sub-title-text-color: $colors--gray-banner;
  $sub-title-text-color-larger: $colors--primary-text;
  $interlocutor-color: $colors--gray-banner;
  $interlocutor-color-larger: $colors--primary-text;

  position: absolute;
  top: 25px;
  right: 20px;
  width: auto;
  min-width: inherit;
  text-align: right;

  @include larger-than($phone-width) {
    position: relative;
    top: 0;
    left: 0;
  }

  .sub-title {
    margin-bottom: 0;
    color: $sub-title-text-color;
    font-size: 12px;
    line-height: 24px;

    @include larger-than($phone-width) {
      color: $sub-title-text-color-larger;
    }

    &.conversation-interlocutor {
      color: $interlocutor-color;

      @include larger-than($phone-width) {
        color: $interlocutor-color-larger;
        font-weight: 600;
      }
    }
  }

  .sub-title + .sub-title {
    margin-top: 20px;

    @include larger-than($phone-width) {
      margin-top: 0;
    }
  }
}
