*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  
}

.fixed {
  position: fixed
}

.relative {
  position: relative
}

.inset-0 {
  inset: 0px
}

.z-50 {
  z-index: 50
}

.z-\[350\] {
  z-index: 350
}

.z-\[9999\] {
  z-index: 9999
}

.m-0 {
  margin: 0px
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.aspect-square {
  aspect-ratio: 1 / 1
}

.h-10 {
  height: 2.5rem
}

.h-\[16px\] {
  height: 16px
}

.h-\[24px\] {
  height: 24px
}

.h-\[32px\] {
  height: 32px
}

.h-\[36px\] {
  height: 36px
}

.h-\[40px\] {
  height: 40px
}

.h-\[48px\] {
  height: 48px
}

.h-full {
  height: 100%
}

.w-10 {
  width: 2.5rem
}

.w-\[16px\] {
  width: 16px
}

.w-\[24px\] {
  width: 24px
}

.w-\[32px\] {
  width: 32px
}

.w-\[36px\] {
  width: 36px
}

.w-\[48px\] {
  width: 48px
}

.w-full {
  width: 100%
}

.min-w-\[200px\] {
  min-width: 200px
}

.min-w-\[8rem\] {
  min-width: 8rem
}

.min-w-\[auto\] {
  min-width: auto
}

.shrink-0 {
  flex-shrink: 0
}

.cursor-default {
  cursor: default
}

.cursor-pointer {
  cursor: pointer
}

.cursor-text {
  cursor: text
}

.select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none
}

.appearance-none {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none
}

.items-center {
  align-items: center
}

.justify-center {
  justify-content: center
}

.gap-2 {
  gap: 0.5rem
}

.overflow-hidden {
  overflow: hidden
}

.whitespace-nowrap {
  white-space: nowrap
}

.rounded-full {
  border-radius: 9999px
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-sm {
  border-radius: 0.125rem
}

.border {
  border-width: 1px
}

.border-0 {
  border-width: 0px
}

.border-\[1px\] {
  border-width: 1px
}

.border-solid {
  border-style: solid
}

.border-none {
  border-style: none
}

.border-important {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 255 / var(--tw-border-opacity))
}

.border-light-gray {
  --tw-border-opacity: 1;
  border-color: rgb(225 225 225 / var(--tw-border-opacity))
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity))
}

.bg-black\/60 {
  background-color: rgb(0 0 0 / 0.6)
}

.bg-important {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 255 / var(--tw-bg-opacity))
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity))
}

.bg-transparent {
  background-color: transparent
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.object-cover {
  -o-object-fit: cover;
     object-fit: cover
}

.p-0 {
  padding: 0px
}

.p-1 {
  padding: 0.25rem
}

.p-\[16px\] {
  padding: 16px
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.px-\[12px\] {
  padding-left: 12px;
  padding-right: 12px
}

.px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem
}

.py-\[13px\] {
  padding-top: 13px;
  padding-bottom: 13px
}

.py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px
}

.py-\[8px\] {
  padding-top: 8px;
  padding-bottom: 8px
}

.pl-8 {
  padding-left: 2rem
}

.font-base {
  font-family: var(--base-fonts)
}

.font-video {
  font-family: var(--video-font)
}

.text-\[10px\] {
  font-size: 10px
}

.text-\[11px\] {
  font-size: 11px
}

.text-\[12px\] {
  font-size: 12px
}

.text-\[13px\] {
  font-size: 13px
}

.text-\[14px\] {
  font-size: 14px
}

.text-\[16px\] {
  font-size: 16px
}

.text-\[20px\] {
  font-size: 20px
}

.text-\[24px\] {
  font-size: 24px
}

.text-\[32px\] {
  font-size: 32px
}

.text-\[48px\] {
  font-size: 48px
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.font-bold {
  font-weight: 700
}

.font-normal {
  font-weight: 400
}

.font-semibold {
  font-weight: 600
}

.uppercase {
  text-transform: uppercase
}

.leading-\[13px\] {
  line-height: 13px
}

.leading-\[14px\] {
  line-height: 14px
}

.leading-\[16px\] {
  line-height: 16px
}

.leading-\[18px\] {
  line-height: 18px
}

.leading-\[20px\] {
  line-height: 20px
}

.leading-\[21px\] {
  line-height: 21px
}

.leading-\[22px\] {
  line-height: 22px
}

.leading-\[24px\] {
  line-height: 24px
}

.leading-\[34px\] {
  line-height: 34px
}

.leading-\[38px\] {
  line-height: 38px
}

.leading-\[64px\] {
  line-height: 64px
}

.tracking-\[0\.12px\] {
  letter-spacing: 0.12px
}

.tracking-\[0\.14px\] {
  letter-spacing: 0.14px
}

.tracking-\[0\.16px\] {
  letter-spacing: 0.16px
}

.tracking-\[0\.26px\] {
  letter-spacing: 0.26px
}

.tracking-\[0\.28px\] {
  letter-spacing: 0.28px
}

.tracking-\[0\.2px\] {
  letter-spacing: 0.2px
}

.tracking-\[0\.32px\] {
  letter-spacing: 0.32px
}

.tracking-\[0\.44px\] {
  letter-spacing: 0.44px
}

.text-inherit {
  color: inherit
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition-colors {
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

@keyframes enter {
  from {
    opacity: var(--tw-enter-opacity, 1);
    transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0))
  }
}

@keyframes exit {
  to {
    opacity: var(--tw-exit-opacity, 1);
    transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0))
  }
}

.placeholder\:text-secondary::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity))
}

.placeholder\:text-secondary::placeholder {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity))
}

.hover\:border-alert-new:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 122 255 / var(--tw-border-opacity))
}

.hover\:border-darkest-gray:hover {
  --tw-border-opacity: 1;
  border-color: rgb(52 52 52 / var(--tw-border-opacity))
}

.hover\:border-primary:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity))
}

.hover\:bg-alert-new:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 122 255 / var(--tw-bg-opacity))
}

.hover\:bg-darkest-gray:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(52 52 52 / var(--tw-bg-opacity))
}

.hover\:bg-off-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity))
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus-visible\:outline-none:focus-visible {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.focus-visible\:ring-blue-500:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity))
}

.focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none
}

.disabled\:opacity-50:disabled {
  opacity: 0.5
}

.data-\[disabled\]\:pointer-events-none[data-disabled] {
  pointer-events: none
}

.data-\[side\=left\]\:h-\[var\(--radix-popover-trigger-height\)\][data-side=left] {
  height: var(--radix-popover-trigger-height)
}

.data-\[side\=right\]\:h-\[var\(--radix-popover-trigger-height\)\][data-side=right] {
  height: var(--radix-popover-trigger-height)
}

.data-\[side\=bottom\]\:w-\[var\(--radix-popover-trigger-width\)\][data-side=bottom] {
  width: var(--radix-popover-trigger-width)
}

.data-\[side\=top\]\:w-\[var\(--radix-popover-trigger-width\)\][data-side=top] {
  width: var(--radix-popover-trigger-width)
}

.data-\[disabled\]\:opacity-50[data-disabled] {
  opacity: 0.5
}

.data-\[state\=closed\]\:duration-500[data-state=closed] {
  transition-duration: 500ms
}

.data-\[state\=open\]\:duration-500[data-state=open] {
  transition-duration: 500ms
}

.data-\[state\=open\]\:animate-in[data-state=open] {
  animation-name: enter;
  animation-duration: 150ms;
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial
}

.data-\[state\=closed\]\:animate-out[data-state=closed] {
  animation-name: exit;
  animation-duration: 150ms;
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial
}

.data-\[state\=closed\]\:fade-out-0[data-state=closed] {
  --tw-exit-opacity: 0
}

.data-\[state\=open\]\:fade-in-0[data-state=open] {
  --tw-enter-opacity: 0
}

.data-\[state\=closed\]\:zoom-out-95[data-state=closed] {
  --tw-exit-scale: .95
}

.data-\[state\=open\]\:zoom-in-95[data-state=open] {
  --tw-enter-scale: .95
}

.data-\[side\=bottom\]\:slide-in-from-top-2[data-side=bottom] {
  --tw-enter-translate-y: -0.5rem
}

.data-\[side\=left\]\:slide-in-from-right-2[data-side=left] {
  --tw-enter-translate-x: 0.5rem
}

.data-\[side\=right\]\:slide-in-from-left-2[data-side=right] {
  --tw-enter-translate-x: -0.5rem
}

.data-\[side\=top\]\:slide-in-from-bottom-2[data-side=top] {
  --tw-enter-translate-y: 0.5rem
}

.data-\[state\=closed\]\:duration-500[data-state=closed] {
  animation-duration: 500ms
}

.data-\[state\=open\]\:duration-500[data-state=open] {
  animation-duration: 500ms
}

.\[\&\>svg\]\:h-\[100\%\]>svg {
  height: 100%
}

.\[\&\>svg\]\:w-\[100\%\]>svg {
  width: 100%
}

.\[\&_svg\]\:pointer-events-none svg {
  pointer-events: none
}

.\[\&_svg\]\:size-4 svg {
  width: 1rem;
  height: 1rem
}

.\[\&_svg\]\:shrink-0 svg {
  flex-shrink: 0
}