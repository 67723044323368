@import "mixins/type/index";
@import "mixins/media-queries/index";

.pageFooter {
  border-top: 1px solid var(--colors--light-stroke);
  flex-grow: 0;
  width: 100%;

  &.isBorderless {
    border-top: none;
  }

  .links {
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    align-items: flex-end;
    width: 100%;
    border-top: 1px solid var(--colors--light-stroke);

    @include larger-than-tablet {
      padding: 30px;

      @include larger-than-laptop {
        border-top: none;
      }
    }
  }

  .siteLink {
    font-weight: 600;
  }

  .siteLink + .socialLink {
    @include larger-than-laptop {
      margin-left: auto;
    }
  }

  .copyright {
    @include type--form-label;
    margin-left: auto;
    text-transform: capitalize;
    text-align: right;
  }

  .socialLink + .copyright {
    @include larger-than-laptop {
      margin-left: 35px;
    }
  }
}
