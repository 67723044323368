@import "app/assets/stylesheets/mixins/button/_index.scss";

.conversation-item-cta {
  @include button--all-styles-removed;
  $border-color: $colors--medium-stroke;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  border-bottom: 1px solid $border-color;

  // listing image, title, desgisnger
  .conversation-listing-link {
    // NOTE: width appears different in "cta-mode" because the "links" container
    // does _not_ line up with the content
    width: 329px;
  }

  @import "./conversation-links-wrapper";
}
