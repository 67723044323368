.content {
  padding: 2.4rem 1.6rem;
  width: 100%;
  max-width: min(100vw, 120rem);
  display: flex;
  flex-direction: column;
}

.header {
  margin-bottom: 2rem;
}

.capitalize {
  text-transform: capitalize;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  margin-bottom: 1.6rem;
}

.column {
  display: flex;
  flex-direction: column;
  width: calc((100% / var(--content-grid-columns)) - (((var(--content-grid-columns) - 1) / var(--content-grid-columns)) * 4rem));
  gap: 2rem;
}

.link {
  text-decoration: none;
  color: inherit;
  transition: color 150ms ease-in-out;

  &:hover {
    color: var(--colors--blue-highlight);
  }
}

.cta {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}
