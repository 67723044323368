@import "storefront/components/styles/pxToRem.module.scss";
@import "mixins/type/index";

.legal {
  @include type--body-caption;
  margin-top: 0;
  margin-bottom: 3rem;

  a {
    color: inherit;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      color: var(--colors--gray-banner);
    }
  }
}

.lineBreak {
  margin: 24px 0;
  border-width: 0;
  border-top: 1px dotted #e1e1e1;
}

.usersAuthentication {
  text-align: center;
}

.container {
  margin: 0 auto;
  max-width: 345px;

  :global {
    .Form {
      padding-right: 0;
      padding-left: 0;
      margin-bottom: 0;

      .FormField {
        &._successful::after {
          top: pxToRem(38);
        }

        .--label {
          @include type--headline-2;
          font-weight: $type--weights--heavy;
          font-size: 16px;
          line-height: 130%;
          letter-spacing: -0.03em;
          text-align: left;
        }

        .--input,
        .--error {
          text-align: left;
        }
      }
    }
  }
}

.message {
  text-align: left;
  line-height: 21px;
  margin-top: 0;
  margin-bottom: 3rem;

  a {
    @include type--secondary-link;
    font-size: inherit;
  }
}

button + .message {
  padding-top: 15px;
}

.error {
  color: var(--colors--alert);
  border: 1px solid var(--colors--alert);
  border-radius: 4px;
  padding: 10px 18px;
  margin-bottom: 0;
  background-color: var(--colors--bad-tint);
  text-align: left;

  + :global(.FormField) {
    margin-top: 20px;
  }
}

button + .error {
  margin-top: 20px;
}
