.interlocutor {
  $border-color: $colors--tertiary-text;
  $sub-title-text-color: $colors--primary-text;
  // $close-icon-color: $colors--medium-stroke;

  $listing-image-size: 43px;
  $close-width: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 20px 20px 20px 15px;
  border-bottom: 1px solid $border-color;

  // this is the back button
  .close {
    height: 43px;
    width: 43px;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover, &:focus {
      box-shadow: none;
    }
  }

  .sub-title {
    margin-bottom: 0;
    text-align: center;

    a {
      font-size: 18px;
      letter-spacing: normal;
      font-weight: 300;
      text-decoration: none;
      color: $sub-title-text-color;
    }
  }

  .conversation-listing-image-mobile {
    height: $listing-image-size;
    width: $listing-image-size;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 100%;
  }
}
