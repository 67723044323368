@import "mixins/media-queries/index";

.avatar {
  height: 2.4rem;
  width: 2.4rem;
  @include larger-than-tablet {
    height: 3.6rem;
    width: 3.6rem;
  }
}
