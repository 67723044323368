.success {
  color: var(--colors--success);
}

.alert {
  color: var(--colors--alert);
}

.warning {
  color: var(--colors--warning);
}

.default {
  color: var(--colors--secondary-text);
}
