.fieldWrapper {
  display: flex;
  flex-direction: column;

  [data-control-type="label"] + :global(.Field-Control) {
    margin-top: 10px;
  }

  :global(.Field-Control) + :global(.Field-Note),
  :global(.Field-Control) + [data-control-type="error"] {
    margin-top: 10px;
  }
}
