@import "mixins/type/index";
@import "storefront/components/styles/pxToRem.module.scss";

/**
 * Link styles apply to both our Link and LinkButton components.
 * The LinkButton component is a button styled like a link.
 * If you add new variants here, be sure to update both stories to
 * accept the new variants as well
 */

.link {
  &.inherit {
    color: inherit;
    text-decoration: none;
  }
  &.default {
    color: var(--colors--blue-highlight);
    text-decoration: underline;
  }
  &.underline {
    color: inherit;
    text-decoration: underline;
  }
  &.superLink {
    @include type--super-link;
  }
  &.underlineArrow {
    @include type--super-link;
    color: inherit;
    text-decoration: underline;
    font-size: inherit;
  }
  &.circlePlus {
    color: inherit;
    text-decoration: underline;
    font-size: inherit;

    &::before {
      padding: pxToRem(4) pxToRem(5) 0 0;
      content: url("data:image/svg+xml,%3Csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 14C10.8294 14 14 10.8225 14 7C14 3.17059 10.8225 0 6.99314 0C3.17059 0 0 3.17059 0 7C0 10.8225 3.17745 14 7 14ZM3.69216 7.00686C3.69216 6.65 3.93922 6.40294 4.30294 6.40294H6.39608V4.30294C6.39608 3.94608 6.63627 3.69216 6.98627 3.69216C7.34314 3.69216 7.5902 3.94608 7.5902 4.30294V6.40294H9.69706C10.0471 6.40294 10.2941 6.65 10.2941 7.00686C10.2941 7.35686 10.0471 7.5902 9.69706 7.5902H7.5902V9.69706C7.5902 10.0471 7.34314 10.301 6.98627 10.301C6.63627 10.301 6.39608 10.0471 6.39608 9.69706V7.5902H4.30294C3.94608 7.5902 3.69216 7.35686 3.69216 7.00686Z' fill='black'/%3E%3C/svg%3E%0A");
    }
  }
}
