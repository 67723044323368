@import "app/assets/stylesheets/mixins/button/_index.scss";

.conversation-item-activity-log-expander {
  @include button--all-styles-removed;
  width: 100%;
  margin-bottom: 0;
  padding: 15px 0;

  cursor: pointer;
  background-color: $colors--light-stroke;

  display: flex;
  justify-content: center;

  h1 {
    color: $colors--secondary-text;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0;
    background-color: $colors--light-stroke;
    padding: 0 15px;
  }

  &:hover {
    h1 {
      color: $colors--medium-stroke;
    }
  }
}
