.emailNotification {
  display: flex;
  margin: 20px 0;

  .checkbox {
    width: 100%;
    text-align: start;
    padding-left: 22px;
    cursor: pointer;
    position: relative;
    user-select: none;

    input {
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;

      &:checked ~ :global(.--checkmark) {
        border: 1px solid var(--colors--primary-text);
        background-color: var(--colors--primary-text);
      }

      &:checked ~ :global(.--checkmark:after) {
        display: block;
      }
    }

    :global(.--checkmark) {
      border-radius: 4px;
      border: 1px solid var(--colors--medium-stroke);
      box-sizing: border-box;
      height: 15px;
      left: 0;
      position: absolute;
      top: 3px;
      width: 15px;

      &:after {
        border-radius: 2px;
        content: "";
        display: none;
        border: solid var(--colors--white-background);
        border-width: 0 2px 2px 0;
        height: 8px;
        width: 4px;
        transform: rotate(45deg) translate(3px, -2px);
      }

      &:hover {
        border: 2px solid var(--colors--medium-stroke);
      }
    }

    :global(.--label) {
      display: block;
      line-height: 20px;
    }
  }
}
