@import "./z-index";

.FullPageOverlay {
  background-color: $colors--primary-text;
  opacity: 0;
  position: fixed;

  &._searching {
    opacity: 0.5;
    z-index: $z-flash-message;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
