@import "mixins/tooltip";

.tooltip {
  @include tooltip;

  :global(.react-tooltip-lite) {
    line-height: 18px;
    width: 240px;
  }
}
