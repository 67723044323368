@import "../mixins/badge/index";

.conversation-loading {
  padding: 50px 0;
}

.conversation-item-activity-log {
  .activity-log-item-details {
    h3 {
      display: flex;
      align-items: center;

      a {
        .-verb {
          color: $colors--info;
          font-weight: bold;
          font-size: 14px;
          vertical-align: middle;
        }

        .-badge {
          @include badge;
        }

        .-verb + .-badge {
          margin-left: 5px;
        }
      }
    }
  }
}
.conversation-interlocutor {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.conversation-listing-image {
  flex: none;
}

.gray {
  color: $colors--secondary-text;
}
