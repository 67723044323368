@import "mixins/media-queries/index";

.Editorial--DropProductsModule {
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px auto;

  @include larger-than-tablet {
    margin: 40px auto;
  }
}
