.selectControl {
  --background-color: var(--colors--white-background);
  --text-color: var(--colors--primary-text);
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  border: 0;
  border-radius: 4px;

  &:before {
    content: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 14 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.68934 5.75L12.4697 0.969666L13.5303 2.03033L8.31066 7.25L5.68934 7.25L0.469675 2.03033L1.53033 0.969665L6.31066 5.75L7.68934 5.75Z' fill='black'/%3E%3C/svg%3E%0A");
    position: absolute;
    top: 48%;
    right: 20px;
    z-index: 2;
    transform: translateY(-50%);
    pointer-events: none;
  }

  &:after {
    content: "";
    position: absolute;
    top: 4px;
    right: 1px;
    z-index: 1;
    display: block;
    width: 60px;
    height: calc(100% - 8px);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      var(--background-color) 20px
    );
    pointer-events: none;
  }

  &.disabled {
    --background-color: var(--colors--off-white-background);
    --text-color: var(--colors--secondary-text);

    &:before {
      content: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 14 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.68934 5.75L12.4697 0.969666L13.5303 2.03033L8.31066 7.25L5.68934 7.25L0.469675 2.03033L1.53033 0.969665L6.31066 5.75L7.68934 5.75Z' fill='%23737373'/%3E%3C/svg%3E%0A");
    }
  }
}

.input {
  width: 100%;
  appearance: none;
  height: auto;

  &.empty {
    color: var(--colors--secondary-text);
  }
}
