@import '../../../mixins/type/index';
@import '../../../mixins/media-queries/index';

.UsersPaypalSignup {
  padding: 10% 0;
  width: 90%;
  max-width: 500px;
  margin: 0 auto;

  &._unauthenticated._completed {
    max-width: 345px;
  }

  @include larger-than-phone {
    padding-bottom: calc(10% + 100px);
  }

  @include larger-than-laptop {
    padding: 5% 0;
    padding-bottom: calc(5% + 50px);
  }

  .--title {
    margin: 0;
    @include type--display-3;
    text-align: center;
  }

  .--title + .--body {
    margin-top: 25px;
  }

  .--body {
    display: block;
    margin: 0;
    @include type--base;
    text-align: center;
  }

  .--body + .--body {
    margin-top: 10px;
  }

  .--link {
    @include type--base;
    text-decoration: underline;
  }

  .--title + .--fieldset { margin-top: 35px; }

  .--fieldset {
    max-width: 345px;
    margin: 0 auto;
  }

  .--fieldset + .--submit { margin-top: 35px; }
  .--fieldset + .--recaptcha { margin-top: 20px; }

  .--field {
    margin: 0;
    @include field;
    width: 100%;
    max-width: 345px;
  }

  .--field + .--field { margin-top: 15px; }

  .--recaptcha + .--submit { margin-top: 35px; }
  .--recaptcha + .--error-message { margin-top: 35px; }

  .--submit {
    @include button;
    padding: 14px 18px;
    max-width: 345px;
    width: 100%;
  }

  .--error-message {
    @include type--body-caption;
    color: $colors--alert;
    text-align: center;
  }
}
