@import "../../../mixins/type/index";

$colors--top: #00ABFF;
$colors--bottom: #FF4617;

@mixin heatwave-modal {
  box-sizing: border-box;
  border: 1px solid var(--colors--light-stroke);
  border-radius: 2px;
  box-shadow: 5px 5px $colors--top, -5px -5px $colors--bottom;
}

@mixin heatwave-button {
  @include type--button;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid var(--colors--light-stroke);
  border-radius: 2px;
  cursor: pointer;
  padding: 20px 17px;
  height: auto;
  width: 310px;
  background-color: var(--colors--primary-text);
  color: var(--colors--white-background);
  box-shadow: 5px 5px $colors--top, -5px -5px $colors--bottom;
  transition: 0.5s box-shadow, 0.5s background-color, 0.5s color, 0.5s border;

  &:hover, &:focus {
    box-shadow: 5px 5px $colors--bottom, -5px -5px $colors--top;
    background-color: var(--colors--white-background);
    color: var(--colors--primary-text);
    border: 1px solid var(--colors--primary-text);
  }

  &._white {
    background-color: var(--colors--white-background);
    color: var(--colors--primary-text);
    border: 1px solid var(--colors--primary-text);

    &:hover, &:focus {
      box-shadow: 5px 5px $colors--bottom, -5px -5px $colors--top;
      background-color: var(--colors--primary-text);
      color: var(--colors--white-background);
      border: 1px solid var(--colors--white-background);
    }
  }
}

// TYPES

@mixin heatwave--type-x-large {
  @include type--base;
  @include type--font-size-and-line-height(45px);
  @include type--marketplace-header-font;

}

@mixin heatwave--type-large {
  @include type--base;
  @include type--font-size-and-line-height(35px);
  @include type--marketplace-header-font;
}

@mixin heatwave--type-medium {
  @include type--base;
  @include type--font-size-and-line-height(25px);
  @include type--marketplace-header-font;
}

@mixin heatwave--type-body {
  @include type--base;
  @include type--font-size-and-line-height(20px);
}

@mixin heatwave--type-section-label {
  @include type--base;
  font-weight: $type--weights--bold;
  @include type--font-size-and-line-height(20px);
  text-transform: uppercase;
  font-family: $type--families--system;
}

@mixin heatwave--countdown {
  .--countdown {
    display: flex;
    color: var(--colors--white-background);
    margin-bottom: 50px;
    text-align: center;
    justify-content: center;

    .--unit {
      width: 35px;
      @include larger-than-phone {
        width: 50px;
      }

      .--number {
        @include type--font-size-and-line-height(30px);
        font-weight: $type--weights--bold;

        @include larger-than-phone {
          @include type--font-size-and-line-height(45px);
        }
      }
      .--name {
        @include type--primary-link;
      }
    }
  }
}

