@import '../../../mixins/type/index';
@import '../../../mixins/button/index';

.Ad--Landing {
  .--Hero {
    position: relative;
    margin-bottom: 40px;
    overflow: hidden; 
    height: 480px;
    background-size: cover;
    background-position: center;
    
    @include larger-than-phone {
      margin-bottom: 60px;
    }
    
    .--overlay {
      position: absolute;
      top: 0;
      height: 480px;
      display: flex;
      padding: 0 10%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      @include larger-than-phone {
        padding: 0 20%;
      }
      
      .--title {
        @include type--display-3;
        color: $colors--white-background;
        text-align: center;
        margin-bottom: 30px;
        
        @include larger-than-phone {
          @include type--display-2;
          color: $colors--white-background;
          text-align: center;
        }
      }
      
      .--button {
        @include button;
        
        @include larger-than-phone {
          &._x-large {
            width: 300px;
          }
        }
        
        &:hover {
          background-color: $colors--primary-text;
          color: $colors--white-background;
        }
      }
    }
  }
}