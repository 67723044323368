._photos {
  .right {
    text-align: right;

    .--icon {
      margin: -2px 3px 0 0;
      vertical-align: middle;
    }
  }
}
