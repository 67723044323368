@import "../../../app/assets/stylesheets/mixins/media-queries";
@import "../../../app/assets/stylesheets/mixins/type";
@import "../../../app/assets/stylesheets/shared/z-index";

.modal-overlay {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: $z-general-overlay;

  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    position: relative;
    background: var(--colors--white-background);
    -webkit-overflow-scrolling: touch;

    max-width: 620px;
    max-height: 95%;
    padding: 100px 80px;
    box-sizing: border-box;

    box-shadow: 0 0 18px rgba(0, 0, 0, 0.4);

    overflow-y: auto;

    .enter-the-site {
      .buttons-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 200px;
        margin: 0 auto;
      }
    }
  }

  .close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    background: var(--colors--white-background);
    height: 20px;
    width: 20px;
    z-index: 10;

    svg {
      height: 16px;
      width: 17px;
    }
  }

  button {
    width: 100%;
    height: 4rem;
    font-size: 14px;

    &.mini {
      width: 30%;
    }
  }

  h1 {
    margin-bottom: 3rem;
    line-height: 26px;
  }
  p {
    margin-top: 0;
    margin-bottom: 3rem;
  }
  .form-field {
    margin-bottom: 3rem;
  }
}

.modal._hasHeader {
  padding: 0;
  width: 100%;
  height: 100%;
  max-height: none;

  @include larger-than-tablet {
    width: 680px;
    height: auto;
    max-height: 95%;

    &._wide {
      max-width: 950px;
      width: auto;
    }
  }

  .Modal-Header {
    width: 100%;
    min-height: 60px;
    background-color: var(--colors--white-background);
    border-bottom: 1px solid var(--colors--light-stroke);
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;

    .-cover-img-container {
      visibility: hidden;
      width: 45px;
      height: 45px;
      overflow: hidden;
      border-radius: 90px;
      position: absolute;
      left: 20px;

      @include larger-than-tablet {
        visibility: visible;
      }

      .-cover-img {
        width: 45px;
        height: 100%;
        object-fit: cover;
      }
    }

    .Modal-Header-Title {
      line-height: 20px;
      @include type--headline-2;
      font-size: 18px;
      text-align: center;
      margin: 0;

      @include larger-than-tablet {
        font-size: 20px;
      }
    }

    .-close-btn-container,
    .-left-arrow-btn-container {
      min-width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
    }

    .-close-btn-container {
      right: 10px;
    }

    .-left-arrow-btn-container {
      left: 10px;
    }

    .-close-btn,
    .-left-arrow-btn {
      margin: 0;
      border: none;
      padding: 0;
      cursor: pointer;
      background: var(--colors--white-background);
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        height: 15px;
        width: 15px;
      }

      &:hover {
        box-shadow: none;
      }
    }

    .-left-arrow-text {
      margin-left: 5px;
    }
  }

  .Modal-Body {
    display: flex;
    flex-direction: column;
    padding: 5%;

    .-subtitle {
      @include type--body-editorial;
      font-size: 16px;
    }
  }
}

.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.ReactModal__Content {
  display: flex;
  flex-direction: column;
}

.ReactModal__Content--after-open {
  transition: all 150ms ease-in;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transition: all 150ms ease-in;
}

@media only screen and (min-device-width: 0) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .modal {
    width: 100%;
    height: 100%;
    padding: 100px 40px;
    overflow: scroll;
    max-width: none;
    max-height: none;
  }
}
body {
  &.ReactModal__Body--open:not(.conversations, .conversation),
  &.GlobalHeader__menu-expanded {
    overflow: initial;
  }
}
